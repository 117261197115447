// import React, { useContext, useEffect, useRef, useState } from 'react';
// import NavBar from '../components/nav_sidebar/NavBar';
// import SideBar from '../components/nav_sidebar/SideBar';
// import { useLocation, useNavigate } from 'react-router-dom';
// import config from '../config';
// import AuthContext from '../context/AuthContext';

// export default function ReceptionistAddPage() {
//   const [loading, setLoading] = useState(false);
//   const sidebarRef = useRef(null);
//   const { authTokens } = useContext(AuthContext);
//   const navigate = useNavigate();
//     const location = useLocation();
//     const userName = location.state?.userName;
//     const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
//     const OpenSidebar = () => {
//         setOpenSidebarToggle(!openSidebarToggle)
//       }
//     const [receptionist, setReceptionist] = useState({
//         phone_number: '',
//         first_name: '',
//         last_name: '',
//         email: ''
//      });

//      const handleChange = (e) => {
//       setReceptionist({
//         ...receptionist,
//         [e.target.name]: e.target.value
//       });
//    };

//    const handleSubmit = async (e) => {
//     setLoading(true);
//     e.preventDefault();

//     const data = {
//       receptionist_detail: receptionist
//     };

//     try {
//       const response = await fetch(`${config.API_BASE_URL}/api/v1/receptionist/add/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           "Authorization" : `JWT ${authTokens?.access}`
//         },
//         body: JSON.stringify(data)
//       });
//       if (response.status === 200 || response.status === 201) {
//         alert("Receptionist added successfully!!");
//         navigate("/") 
//         console.log('Receptionist added successfully');
//       } 
//       else if (response.status === 409) {
//         alert("Receptionist already added!!");
//         console.log('Receptionist already added');
//       } 
//       else {
//           console.error('Failed to add receptionist');
//           throw new Error('Network response was not ok');
//         }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//     setLoading(false);
//  };

//  useEffect(() => {
//   const handleClickOutside = (event) => {
//     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//       setOpenSidebarToggle(false);
//     }
//   };

//   document.addEventListener('mousedown', handleClickOutside);

//   return () => {
//     document.removeEventListener('mousedown', handleClickOutside);
//   };
// }, []);


//   return (
//     <div className='receptionistpage'>
//         <NavBar OpenSidebar={OpenSidebar} openSidebarToggle={openSidebarToggle} userName={userName}/>
//         <SideBar ref={sidebarRef} openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} userName={userName}/>
//         <form onSubmit={handleSubmit}>
//           <h2>Add a receptionist</h2>
//           <div className="firstrow">
//             <div className="firstname">
//               <label>First name*</label>
//               <input type="text" placeholder="First name" name="first_name" value={receptionist.first_name} onChange={handleChange} required/>
//             </div>
//             <div className="firstname">
//               <label>Last name</label>
//               <input type="text" placeholder="Last name" name="last_name" value={receptionist.last_name} onChange={handleChange}/>
//             </div>
//           </div>
//           <div className="firstrow">
//             <div className="firstname">
//               <label>Phone Number*</label>
//               <input type="text" placeholder="Phone Number" name="phone_number" value={receptionist.phone_number} onChange={handleChange} required/>
//             </div>
//             <div className="firstname">
//               <label>Email</label>
//               <input type="text" placeholder="Email" name="email" value={receptionist.email} onChange={handleChange}/>
//             </div>
//           </div>
//           <div style={{display: 'flex' ,flexDirection: 'column','width':'100%','height':'30%'}}>
//             <h3>Permissions</h3>
//             <div style={{'display':'flex','flexDirection':'row'}}><label htmlFor="Access" style={{'width':'60%'}}>Access to patient records</label> <input id="Access" name='Access' type="checkbox" checked/></div>
//             <div style={{'display':'flex','flexDirection':'row'}}><label htmlFor="manage" style={{'width':'60%'}}>Schedule and manage appointments</label> <input id="manage" name='manage' type="checkbox" checked/></div>
//             <div style={{'display':'flex','flexDirection':'row'}}><label htmlFor="billing" style={{'width':'60%'}}>Manage billing and payments</label> <input id="billing" name='billing' type="checkbox" checked/></div>
//           </div>
//           <div className="firstrow" style={{justifyContent:'flex-end'}}>
//           {loading ? <div className='loading-spinner'></div>:<input type="submit" value="save" />}
//           </div>
//         </form>
//     </div>
//   )
// }


import React, { useContext, useEffect, useRef, useState } from 'react';
import NavBar from '../components/nav_sidebar/NavBar';
import SideBar from '../components/nav_sidebar/SideBar';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../config';
import AuthContext from '../context/AuthContext';

export default function ReceptionistAddPage() {
  const [receptionistList, setReceptionistList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const searchRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const sidebarRef = useRef(null);
  const { authTokens } = useContext(AuthContext);
  const navigate = useNavigate();
    const location = useLocation();
    const userName = location.state?.userName;
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
      }
    const [receptionist, setReceptionist] = useState({
        phone_number: '',
        first_name: '',
        last_name: '',
        email: ''
     });


      // Fetch receptionists list when component mounts
  useEffect(() => {
    const fetchReceptionists = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/v1/receptionist/list/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `JWT ${authTokens?.access}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setReceptionistList(data.receptionists || []);
        } else {
          console.error('Failed to fetch receptionists');
        }
      } catch (error) {
        console.error('Error fetching receptionists:', error);
      }
    };

    if (authTokens?.access) {
      fetchReceptionists();
    }
  }, [authTokens]);

  useEffect(() => {
    // Temporary receptionist list for testing
    const mockReceptionistList = [
      { first_name: 'John', last_name: 'Doe', phone_number: '1234567890', email: 'john.doe@example.com' },
      { first_name: 'Jane', last_name: 'Smith', phone_number: '9876543210', email: 'jane.smith@example.com' },
      { first_name: 'Michael', last_name: 'Johnson', phone_number: '5678901234', email: 'michael.johnson@example.com' },
      { first_name: 'Emily', last_name: 'Davis', phone_number: '8765432109', email: 'emily.davis@example.com' }
    ];
    
    setReceptionistList(mockReceptionistList);
  }, []);

  // Handle click outside of search and dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close sidebar if clicked outside
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebarToggle(false);
      }

      // Close dropdown if clicked outside
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


     const handleChange = (e) => {
      setReceptionist({
        ...receptionist,
        [e.target.name]: e.target.value
      });
   };

   const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const data = {
      receptionist_detail: receptionist
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/api/v1/receptionist/add/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `JWT ${authTokens?.access}`
        },
        body: JSON.stringify(data)
      });
      if (response.status === 200 || response.status === 201) {
        alert("Receptionist added successfully!!");
        navigate("/") 
        console.log('Receptionist added successfully');
      } 
      else if (response.status === 409) {
        alert("Receptionist already added!!");
        console.log('Receptionist already added');
      } 
      else {
          console.error('Failed to add receptionist');
          throw new Error('Network response was not ok');
        }
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
 };

 useEffect(() => {
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setOpenSidebarToggle(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);
// Handle search input changes
const handleSearchChange = (e) => {
  const value = e.target.value;
  setSearchTerm(value);
  setShowDropdown(value.length > 0);
};
 // Handle selecting a receptionist from dropdown
 const handleReceptionistSelect = (selectedReceptionist) => {
  setReceptionist({
    first_name: selectedReceptionist.first_name,
    last_name: selectedReceptionist.last_name,
    phone_number: selectedReceptionist.phone_number,
    email: selectedReceptionist.email
  });
  setSearchTerm(`${selectedReceptionist.first_name} ${selectedReceptionist.last_name}`);
  setShowDropdown(false);
};
  // Filter receptionists based on search term
  const filteredReceptionists = receptionistList.filter(rec => 
    `${rec.first_name} ${rec.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    rec.phone_number.includes(searchTerm) ||
    rec.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='receptionistpage'>
        <NavBar OpenSidebar={OpenSidebar} openSidebarToggle={openSidebarToggle} userName={userName}/>
        <SideBar ref={sidebarRef} openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} userName={userName}/>
        <form onSubmit={handleSubmit}>
          <h2>Add a receptionist</h2>
          <div ref={searchRef} style={{ position: 'relative',width:"49%" }}>
          <label>Search Existing Receptionists</label>
          <input 
            type="text" 
            placeholder="Search by name" 
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={() => setShowDropdown(searchTerm.length > 0)}
            style={{ width: '100%', padding: '20px' }}
          />
          {showDropdown && (
            <div style={{
              position: 'absolute', 
              top: '100%', 
              left: 0, 
              width: '100%', 
              maxHeight: '200px', 
              overflowY: 'auto', 
              border: '1px solid #ddd', 
              backgroundColor: 'white',
              zIndex: 10
            }}>
              {filteredReceptionists.length > 0 ? (
                filteredReceptionists.map((rec, index) => (
                  <div 
                    key={index} 
                    onClick={() => handleReceptionistSelect(rec)}
                    style={{
                      padding: '10px',
                      cursor: 'pointer',
                      borderBottom: '1px solid #eee',
                      hover: { backgroundColor: '#f0f0f0' }
                    }}
                  >
                    {rec.first_name} {rec.last_name} 
                  </div>
                ))
              ) : (
                <div style={{ padding: '10px', color: '#888' }}>
                  No receptionists found
                </div>
              )}
            </div>
          )}
        </div>
          <div className="firstrow">
            <div className="firstname">
              <label>First name*</label>
              <input type="text" placeholder="First name" name="first_name" value={receptionist.first_name} onChange={handleChange} required/>
            </div>
            <div className="firstname">
              <label>Last name</label>
              <input type="text" placeholder="Last name" name="last_name" value={receptionist.last_name} onChange={handleChange}/>
            </div>
          </div>
          <div className="firstrow">
            <div className="firstname">
              <label>Phone Number*</label>
              <input type="text" placeholder="Phone Number" name="phone_number" value={receptionist.phone_number} onChange={handleChange} required/>
            </div>
            <div className="firstname">
              <label>Email</label>
              <input type="text" placeholder="Email" name="email" value={receptionist.email} onChange={handleChange}/>
            </div>
          </div>
          <div style={{display: 'flex' ,flexDirection: 'column','width':'100%','height':'30%'}}>
            <h3>Permissions</h3>
            <div style={{'display':'flex','flexDirection':'row'}}><label htmlFor="Access" style={{'width':'60%'}}>Access to patient records</label> <input id="Access" name='Access' type="checkbox" checked/></div>
            <div style={{'display':'flex','flexDirection':'row'}}><label htmlFor="manage" style={{'width':'60%'}}>Schedule and manage appointments</label> <input id="manage" name='manage' type="checkbox" checked/></div>
            <div style={{'display':'flex','flexDirection':'row'}}><label htmlFor="billing" style={{'width':'60%'}}>Manage billing and payments</label> <input id="billing" name='billing' type="checkbox" checked/></div>
          </div>
          <div className="firstrow" style={{justifyContent:'flex-end'}}>
          {loading ? <div className='loading-spinner'></div>:<input type="submit" value="save" />}
          </div>
        </form>
    </div>
  )
}


