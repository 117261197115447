import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import img2 from './images/Home ideation-8.png';
import './style.css';
import AuthContext from '../context/AuthContext';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const Receptionistsignup = () => {
  const [ErrorMessage, setPasswordMatchError] = useState('');
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { registerUser } = useContext(AuthContext);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const email = e.target.email.value;
    const phone_number = e.target.phone_number.value;
    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;
    const is_receptionist = true;
    const is_doctor = false;

    if (password !== confirmPassword) {
       setPasswordMatchError('Passwords do not match.');
       setLoading(false);
       return;
    }

    try {
      await registerUser('', '', phone_number,email, password, is_doctor,is_receptionist, '' );
    } catch (error) {
      <>
      console.error('Error:', error);
      </>
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="loginpage">
      <div className="column">
        <div className='center-content'>
          <h1>Sign Up</h1>
          <form style={{ width: '65%', margin: '0 auto', textAlign: 'center' }} onSubmit={handleSubmit}>
            <div className="form-group" style={{ gap: "1vw" }}>
              <input className="input-field" type="number" placeholder="Phone Number *" name="phone_number" required />
            </div>
            <div className="form-group" style={{ gap: "1vw" }}>
              <input className="input-field" type="email" placeholder="Email ID" name="email" />
            </div>
            <div className="form-group">
              <div className="input-field">
                <input style={{ "width": "90%" }} type={showPassword ? "text" : "password"} placeholder="Password *" name="password" autoComplete="on" required />
                {showPassword ? <FiEyeOff className='eye' onClick={togglePasswordVisibility} /> : <FiEye className='eye' onClick={togglePasswordVisibility} />}
              </div>
            </div>
            <div className="form-group">
              <div className="input-field">
                <input style={{ "width": "90%" }} type={showConfirmPassword ? "text" : "password"} placeholder="Confirm Password *" name="confirmPassword" autoComplete="on" required />
                {showConfirmPassword ? <FiEyeOff className='eye' onClick={toggleConfirmPasswordVisibility} /> : <FiEye className='eye' onClick={toggleConfirmPasswordVisibility} />}
              </div>
            </div>
            {/* <div className="form-group" style={{ display: 'none' }}>
              <label>
                <input type="checkbox" name="is_receptionist" checked={checked} onChange={handleChange} required />
                Enroll as a Receptionist
              </label>
            </div> */}
            <div style={{ 'height': '6vh', 'width': '100%', 'color': 'red', 'fontSize': '1.1vw' }}>{ErrorMessage}</div>
            <div className="form-group">
              {loading ? <div className='loading-spinner'></div> : <input type="submit" value='Sign Up' />}
            </div>
          </form>
          <Link to="/doc/login" style={{ cursor: 'pointer', color: '#095d7e', textDecoration: 'none',fontSize:"17px",
              fontWeight:"500"  }}>Already a Receptionist?</Link>
        </div>
      </div>
      <div className="column right">
        <div className='center-content'>
          <img style={{ width: '75%' }} src={img2} alt='' />
        </div>
      </div>
    </div>
  );
}

export default Receptionistsignup;
