import React, { useEffect, useState, useRef } from 'react';
import useAxios from '../../utils/useAxios';
import { useNavigate } from "react-router-dom";
import NavBar from '../nav_sidebar/NavBar';
import SideBar from '../nav_sidebar/SideBar';
import './admin.css';

function Dashboard({ setPage }) {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const axiosInstance = useAxios();
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const sidebarRef = useRef(null);
   
    const OpenSidebar = () => {
       setOpenSidebarToggle(!openSidebarToggle);
    };
   
    useEffect(() => {
       const fetchData = async () => {
         try {
           const response = await axiosInstance.get('auth/users/me/');
           
           setUserName(response.data.full_name);
         } catch (error) {
           console.error('Failed to fetch data', error);
         }
       };
   
       fetchData();
   
       const handleClickOutside = (event) => {
         if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
           setOpenSidebarToggle(false);
         }
       };
   
       document.addEventListener('mousedown', handleClickOutside);
   
       return () => {
         document.removeEventListener('mousedown', handleClickOutside);
       };
    }, [axiosInstance]);
  return (
     <div>
          <NavBar 
            OpenSidebar={OpenSidebar} 
            openSidebarToggle={openSidebarToggle} 
            userName={userName} 
          />
          <SideBar 
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle} 
        OpenSidebar={OpenSidebar} 
      />
    <div className="containers">

      <div  className='admincards'  onClick={() => navigate("/receptionists")}>Receptionist</div>
      <div className='admincards' onClick={() => navigate("/doctor")}>Doctor</div>
      <div className='admincards' onClick={() => navigate("/clinic")}>Clinic</div>
    </div>
    </div>
  );
}

export default Dashboard;
