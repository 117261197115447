import React, { useState, useEffect, useContext, useRef } from 'react';
import SearchBar from './SearchBar';
import { FiFilter, FiCalendar } from "react-icons/fi";
import QueuelistTile from './QueuelistTile';
import AuthContext from '../../context/AuthContext';
import CustomCalendar from './CustomCalendar';  // Make sure this path is correct
import axios from 'axios';
import "./style.css";
import config from '../../config';

export default function QueueList({ onItemSelected }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const today = selectedDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });

    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');
    const formatedDate = `${year}-${month}-${day}`;
    const [isSwitchOn, setSwitchOn] = useState(false);
    const { authTokens } = useContext(AuthContext);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [lqlist, setlqList] = useState([]);
    const [aplist, setapList] = useState([]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const calendarRef = useRef(null);

    const toggleSwitch = () => {
        setSwitchOn(!isSwitchOn);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        handleFilterClick();
    };

    
    const users = JSON.parse(localStorage.getItem('user'));
    const currentClinicId = users?.current_clinic;

    const [DoctorData, setDoctorData] = useState([]);
    const [selectedDoctorId, setSelectedDoctorId] = useState(null);
    useEffect(() => {
      const fetchDoctorData = async () => {
          try {
              const response = await axios.get(`${config.API_BASE_URL}/api/v1/receptionist/get-doctor/${users.id}`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${authTokens?.access}`,
                },
              });
              console.log("doctorid",response)
              setDoctorData(response.data);
          } catch (err) {
              // setError(err.message);
              console.error('Error fetching doctor data:', err);
          }
      };
  
      fetchDoctorData();
  }, []);
  
  const handleDoctorChange = async (event) => {
    const selectedDoctorIds = event.target.value;
    setSelectedDoctorId(selectedDoctorIds);}

    useEffect(() => {
        const fetchAppointments = async () => {
            const url = users.is_doctor
                ? `${config.API_BASE_URL}/api/v1/appointment/doctor_specific/?doctor_id=${users.id}&appointment_datetime=${formatedDate}&clinic=${currentClinicId}&type=${selectedOption}`
                : `${config.API_BASE_URL}/api/v1/appointment/doctor_specific/?doctor_id=${selectedDoctorId}&appointment_datetime=${formatedDate}&clinic=${currentClinicId}&type=${selectedOption}`;

            try {
                const response = await axios.get(url, {
                    headers: { "Authorization": `JWT ${authTokens?.access}` },
                });
                const filteredAppointments = response.data.appointments.filter(
                    appointment => appointment.status === 'CR' || appointment.status === 'IP'
                );
                setlqList(filteredAppointments);
                setapList(response.data.appointments);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Initial fetch
        fetchAppointments();

        // Set up polling every 30 seconds
        const intervalId = setInterval(fetchAppointments, 30000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [authTokens, formatedDate, selectedOption, selectedDoctorId]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (showDatePicker && calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDatePicker]);

    const handleIconClick = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleDatePickerChange = (newDate) => {
        setSelectedDate(newDate);
        setShowDatePicker(false);
    };

    const handleFilterClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleItemSelected = (selectedItem) => {
        onItemSelected(selectedItem);
    };

    const filteredAppointments = isSwitchOn ? aplist : lqlist;
    const filteredList = filteredAppointments.filter(appointment =>
        appointment.patient_name && appointment.patient_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    

    return (
        <>
            <SearchBar onChange={(e) => setSearchTerm(e.target.value)} />
                {users.is_receptionist && (
                    <select id="doctor-select" onChange={handleDoctorChange} value={selectedDoctorId || ''} style={{height:"50px"}}>
                <option value="" disabled>Select a doctor</option>
                {DoctorData.length > 0 ? (
                    DoctorData.map((doctorMapping) => (
                        <option key={doctorMapping.id} value={doctorMapping.doctor_data.id}>
                            {`${doctorMapping.doctor_data.first_name} ${doctorMapping.doctor_data.last_name}`}
                        </option>
                    ))
                ) : (
                    <option value="" disabled>No doctors available</option>
                )}
            </select>
                )}
            
            <div className="datefilter">
                <div className='todaydate'>{today}</div>
                <div className="icons">
                    <FiFilter  className='fifiltericon' onClick={handleFilterClick}/>
                    <FiCalendar 
                    className='fifiltericon' onClick={handleIconClick}/>
                </div>
                {showDropdown && (
                    <div className="dropdown-content">
                        <div className='option' onClick={() => handleOptionSelect('')}>All</div>
                        <div className='option' onClick={() => handleOptionSelect('IA')}>New Consult</div>
                        <div className='option' onClick={() => handleOptionSelect('FA')}>Follow Up</div>
                        <div className='option' onClick={() => handleOptionSelect('CA')}>Closed</div>
                    </div>
                )}
                {showDatePicker && (
                    <div className='calendar' ref={calendarRef}>
                        <CustomCalendar
                            selectedDate={selectedDate}
                            onChange={handleDatePickerChange}
                            onClose={() => setShowDatePicker(false)}
                        />
                    </div>
                )}
            </div>
            <div className="queue">
                <div className="list">
                    <QueuelistTile list={filteredList} onItemSelected={handleItemSelected}/>
                </div>
                <div style={{'height':'10%','display':'flex','justifyContent':'center','alignItems':'center','width':'100%','boxShadow': '0px -10px 10px rgba(0, 0, 0, 0.1)'}}>
                    <div className={`sliding-switch ${isSwitchOn ? 'on' : 'off'}`} onClick={toggleSwitch}>
                        <div className="slider">{isSwitchOn ? 'All Patients' : 'Live Queue'}</div>
                        <div className="label on">Live Queue</div>
                        <div className="label off">All Patients</div>
                    </div>
                </div>
            </div>
        </>
    )
}