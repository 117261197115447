
// import React, { useContext, useState } from 'react';
// import { Link } from 'react-router-dom';
// import img2 from './images/Home ideation-7.png';
// import './style.css';
// import AuthContext from '../context/AuthContext';
// import { FiEye, FiEyeOff } from 'react-icons/fi';

// export default function LoginPage() {
//   const { loginUser } = useContext(AuthContext);
//   const [loading, setLoading] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     const phone_number = e.target.phone_number.value;
//     const password = e.target.password.value;

//     try {
//       await loginUser(phone_number, password);
//     } catch (error) {
//       console.error("Login failed", error);
//     }
//     setLoading(false);
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="loginpage">
//       <div className="column">
//         <div className='center-content'>
//           <h1>Log In</h1>
//           <form style={{ width: '65%', margin: '0 auto', textAlign: 'center' }} onSubmit={handleSubmit}>
//             <div className="form-group">
//               <input className="input-field" type="number" placeholder="Phone Number" name="phone_number" required />
//             </div>
//             <div className="form-group">
//               <div className="input-field">
//                 <input style={{ width: "90%" }} type={showPassword ? "text" : "password"} placeholder="Password" name="password" autoComplete="on" required />
//                 {showPassword ? <FiEyeOff className='eye' onClick={togglePasswordVisibility} /> : <FiEye className='eye' onClick={togglePasswordVisibility} />}
//               </div>
//             </div>
//             <div className="form-group">
//               {loading ? <div className='loading-spinner'></div> : <input type="submit" value='Login' />}
//             </div>
//           </form>
//           <Link to="/doc/signup" style={{ cursor: 'pointer', color: '#095d7e', textDecoration: 'none' }}>Doctor Signup</Link>
//           <Link to="/rec/receptionistsignup" style={{ cursor: 'pointer', color: '#095d7e', textDecoration: 'none' }}>Receptionist Signup</Link>
//         </div>
//       </div>
//       <div className="column right">
//         <div className='center-content'>
//           <img style={{ width: '75%' }} src={img2} alt='' />
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import AuthContext from '../context/AuthContext';
import './style.css';
import img2 from './images/Home ideation-7.png';

export default function LoginPage() {
  const { loginUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const phone_number = e.target.phone_number.value;
    const password = e.target.password.value;
    try {
      await loginUser(phone_number, password);
    } catch (error) {
      console.error("Login failed", error);
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRoleSelection = (role) => {
    console.log("Selected role:", role);
    setShowRoleModal(false);
    switch (role) {
      case 'doctor':
        navigate('/doc/signup');
        break;
      case 'clinicAdmin':
        navigate('/admin-signup');
        break;
      case 'Receptionist':
        navigate('/rec/receptionistsignup');
        break;
      default:
        break;
    }
  };

  return (
    <div className="loginpage">
      <div className="column">
        <div className='center-content'>
          <h1>Log In</h1>
          <form style={{ width: '65%', margin: '0 auto', textAlign: 'center' }} onSubmit={handleSubmit}>
            <div className="form-group">
              <input 
                className="input-field" 
                type="number" 
                placeholder="Phone Number" 
                name="phone_number" 
                required 
              />
            </div>
            <div className="form-group">
              <div className="input-field">
                <input 
                  style={{ width: "90%" }} 
                  type={showPassword ? "text" : "password"} 
                  placeholder="Password" 
                  name="password" 
                  autoComplete="on" 
                  required 
                />
                {showPassword ? 
                  <FiEyeOff className='eye' onClick={togglePasswordVisibility} /> : 
                  <FiEye className='eye' onClick={togglePasswordVisibility} />
                }
              </div>
            </div>
            <div className="form-group">
              {loading ? 
                <div className='loading-spinner'></div> : 
                <input type="submit" value='Login' />
              }
            </div>
          </form>
          <button 
            onClick={() => setShowRoleModal(true)}
            style={{ cursor: 'pointer', color: '#095d7e', background: 'none', border: 'none', textDecoration: 'none',fontSize:"17px" }}
          >
            Don't have an account? <strong>Signup</strong>
          </button>
         

          {showRoleModal && (
            <div className="modals">
              <div className="modal-content">
                <h2>Select Your Role</h2>
                <p>Please select your role to continue with the signup process.</p>
                <button 
                  className='rolebtn'
                  onClick={() => handleRoleSelection('doctor')}
                >
                  Doctor
                </button>
                <button 
                  className='rolebtn'
                  onClick={() => handleRoleSelection('clinicAdmin')}
                >
                  Clinic Admin
                </button>
                {/* <button 
                 className='rolebtn' 
                  onClick={() => handleRoleSelection('Receptionist')}
                >
                  Receptionist
                </button> */}
                <button 
                  className="modal-button cancel"
                  onClick={() => setShowRoleModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="column right">
        <div className='center-content'>
          <img style={{ width: '75%' }} src={img2} alt='Signup Illustration' />
        </div>
      </div>
    </div>
  );
}
