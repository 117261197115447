import React, { useContext, useEffect, useRef, useState } from "react";
import NavBar from "../components/nav_sidebar/NavBar";
import SideBar from "../components/nav_sidebar/SideBar";
import "./style.css";
import { FcNext, FcPrevious } from "react-icons/fc";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isSameDay,
  getDay,
} from "date-fns";
import { useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import config from "../config";
import axios from "axios";
import PatientRecord from "../components/homepagecomponents/PatientRecord";
const swal = require("sweetalert2");

export default function BookAppointment() {
  const location = useLocation();
  const { appointment } = location.state;
  const sidebarRef = useRef(null);
  const userName = location.state?.userName;
  const [loading, setLoading] = useState(false);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(currentMonth);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [bookedSlots, setBookedSlots] = useState({});
  const [slotConfigurations, setSlotConfigurations] = useState([]);
  const [doctorLeaves, setDoctorLeaves] = useState([]);
  

  const renderDaysOfWeek = () => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days.map((day, index) => <div key={index}>{day}</div>);
  };

  const isDateInLeavePeriod = (checkDate, leaveStart, leaveEnd) => {
    const date = new Date(checkDate);
    date.setHours(0, 0, 0, 0);
    
    const start = new Date(leaveStart);
    start.setHours(0, 0, 0, 0);
    
    const end = new Date(leaveEnd);
    end.setHours(0, 0, 0, 0);
    
    return date >= start && date <= end;
  };
  const getLeaveStatus = (date) => {
    if (!date) return { isOnLeave: false };

    const matchingLeaves = doctorLeaves.filter(leave => 
      isDateInLeavePeriod(date, leave.startDate, leave.endDate)
    );

    if (!matchingLeaves.length) return { isOnLeave: false };

    // Check for full day leave
    const fullDayLeave = matchingLeaves.find(leave => leave.sessions === 'All');
    if (fullDayLeave) {
      return { 
        isOnLeave: true, 
        isFullDay: true 
      };
    }

    // Process session-specific and slot-specific leaves
    const sessionLeaves = matchingLeaves.filter(leave => leave.sessions !== 'All');
    if (sessionLeaves.length) {
      const blockedSessions = sessionLeaves.map(leave => ({
        session: leave.sessions,
        slots: leave.slots === 'All' ? 'All' : 
              typeof leave.slots === 'string' ? leave.slots.split(',').map(s => s.trim()) : 
              Array.isArray(leave.slots) ? leave.slots : []
      }));

      return {
        isOnLeave: true,
        isFullDay: false,
        blockedSessions
      };
    }

    return { isOnLeave: false };
  };

  const updateAvailableSlots = (date, slots) => {
    const dayOfWeek = getDay(date);
    const availableSlotsForDay = slots.filter((slot) => 
      slot.days.includes(dayOfWeek) && slot.isEnabled
    );
    setAvailableSlots(availableSlotsForDay);
    setSelectedSlot(null);

    if (availableSlotsForDay.length > 0) {
      setSelectedSession('MORNING');
    } else {
      setSelectedSession(null);
    }
  };
  const handleDateClick = (date) => {
    const leaveStatus = getLeaveStatus(date);
    if (leaveStatus.isFullDay) return;

    setSelectedDate(date);
    setSelectedSession(null);
    setSelectedSlot(null);
    
    const storedSlots = JSON.parse(localStorage.getItem('appointmentSlots')) || [];
    updateAvailableSlots(date, storedSlots);
  };
  const isSlotBooked = (session, slotNumber) => {
    const dateKey = format(selectedDate, 'yyyy-MM-dd');
    return bookedSlots[dateKey]?.[session]?.includes(slotNumber);
  };
  const [sessions, setSessions] = useState([]);
  const normalizeSessionName = (sessionName) => {
    if (!sessionName) return '';
    return sessionName.toUpperCase().replace(' ', '');
  };



  const [DoctorData, setDoctorData] = useState([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  useEffect(() => {
    const fetchDoctorData = async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/api/v1/receptionist/get-doctor/${user.user_id}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${authTokens?.access}`,
              },
            });
            console.log("doctorid",response)
            setDoctorData(response.data);
        } catch (err) {
            // setError(err.message);
            console.error('Error fetching doctor data:', err);
        }
    };

    fetchDoctorData();
}, []); 



const handleDoctorChange = async (event) => {
  const selectedDoctorIds = event.target.value;
  setSelectedDoctorId(selectedDoctorIds);}
  console.log("doctoridjhkh",selectedDoctorId)
   // Fetch data when the selected date changes
  
  useEffect(() => {
   
    const fetchSessions = async () => {
      setLoading(true);
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
          console.error("User not found in  storage");
          setLoading(false);
          return;
        }
        

        // console.log("jnjnjkkhjkhjkhjkhkhjk",DoctorData)

        // Determine the API endpoint
        const url = user.is_doctor
          ? `${config.API_BASE_URL}/api/v1/booking-slot/slots/${user.id}/sessions/?date=${format(
                       selectedDate,
                    "yyyy-MM-dd"
                    )}`
          : `${config.API_BASE_URL}/api/v1/booking-slot/slots/${selectedDoctorId}/sessions/?date=${format(
              selectedDate,
              "yyyy-MM-dd"
            )}`;

        // Fetch data
        const response = await fetch(url, {
          headers: {
                      Authorization: `JWT ${authTokens?.access}`,
                   },
        });

       
        if (!response.ok) {
          throw new Error("Failed to fetch sessions");
        }
        const data = await response.json();
        console.log("Fetched Sessions:", data);
        setSessions(data.sessions || []);
      } catch (error) {
        console.error("Error fetching sessions:", error);
        setSessions([]);
      } finally {
        setLoading(false);
      }
    };

  console.log("jkkjkdfdddfff")
      fetchSessions();
    
  }, [selectedDate,selectedDoctorId]);


  console.log(sessions)
  const handleBooking = (sessionName) => {
    const updatedSessions = sessions.map((session) =>
      session.session_name === sessionName
        ? {
            ...session,
            booked_patients: session.booked_patients + 1,
          }
        : session
    );
    setSessions(updatedSessions);
    setSelectedSession(sessionName);
  };

  const renderSessionDetails = () => {
    if (!selectedSession ) return <h5>Please select a session to view available slots.</h5>;

    const sessionInfo = sessions.find(
      (session) => session.session_name === selectedSession
    );

    if (!sessionInfo) {
      return <h5>{selectedSession} session is not available.</h5>;
    }

    return (
      <div > 
       
        <h5 >
        Start Time ({sessionInfo.start_time}) -  End Time ({sessionInfo.end_time})
        </h5>
       
        {/* <p>
          <strong>Max Patients:</strong> {sessionInfo.max_patients}
        </p>
        <p>
          <strong>Booked Patients:</strong> {sessionInfo.booked_patients}
        </p>
        <p>
          <strong>Available Patients:</strong> {sessionInfo.available_patients}
        </p> */}
        <h4 >
          Available Patients:{" "}
          {sessionInfo.max_patients - sessionInfo.booked_patients}
        </h4>
      </div>
    );
  };

  

  useEffect(() => {
    if (selectedDate) {
      const dayOfWeek = getDay(selectedDate);
      // Filter configurations for the selected day
      const availableConfigs = slotConfigurations.filter(config => 
        config.days.includes(dayOfWeek) && config.isEnabled
      );
      setAvailableSlots(availableConfigs);
    }
  }, [selectedDate, slotConfigurations]);


  
  const renderAvailableSlots = () => {
    if (!selectedSession) return <div>Select a session to view available slots</div>;
   
    if (!availableSlots.length) return <div>No slots available for the selected session</div>;
  
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {availableSlots.map((slot, index) => (
          <button
            key={index}
            onClick={() => setSelectedSlot(slot.time)}
            style={{
              backgroundColor: selectedSlot === slot.time ? '#6bacaf' : '#eee',
              padding: '10px',
              borderRadius: '4px',
            }}
          >
            {slot.time} ({slot.availableSpots} spots)
          </button>
        ))}
      </div>
    );
  };
  


  const processAvailableSlots = (sessionData, selectedSessionName) => {
    console.log("Processing available slots:");
    console.log("Selected Session:", selectedSessionName);
    console.log("Session data:", sessionData);
  
    const normalizedSelectedSession = normalizeSessionName(selectedSessionName);
    console.log("Normalized Selected Session:", normalizedSelectedSession);
  
    const sessionInfo = sessionData.find(
      session => normalizeSessionName(session.session_name) === normalizedSelectedSession
    );
  
    // Debug normalized names for all sessions in the sessionData
    console.log(
      "Normalized session names in data:",
      sessionData.map(session => ({
        original: session.session_name,
        normalized: normalizeSessionName(session.session_name),
      }))
    );
  
    if (!sessionInfo) {
      console.warn("No session info found for:", selectedSessionName);
      setAvailableSlots([]);
      return;
    }
  
    const availableSlots = generateTimeSlots(
      sessionInfo.start_time,
      sessionInfo.end_time,
      sessionInfo.available_patients || sessionInfo.max_patients || 0
    );
  
    console.log("Generated available slots:", availableSlots);
    setAvailableSlots(availableSlots);
  };
  
  

  // Function to generate time slots
  const generateTimeSlots = (startTime, endTime, maxPatients) => {
    // Safety checks
    if (!startTime || !endTime) {
      console.warn("Invalid start or end time", { startTime, endTime });
      return [];
    }

    const slots = [];
    const [startHour, startMinute] = startTime.split(':').slice(0,2).map(Number);
    const [endHour, endMinute] = endTime.split(':').slice(0,2).map(Number);
    
    // Assume 30-minute slots
    const slotDuration = 30;
    
    let currentHour = startHour;
    let currentMinute = startMinute;

    while (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute)) {
      // Format the time slot
      const formattedSlot = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
      
      slots.push({
        time: formattedSlot,
        availableSpots: maxPatients
      });

      // Increment time
      currentMinute += slotDuration;
      if (currentMinute >= 60) {
        currentHour += Math.floor(currentMinute / 60);
        currentMinute = currentMinute % 60;
      }
    }

    return slots;
  };




  const renderSessionButtons = () => {
    const allSessions = ['MORNING', 'AFTERNOON', 'EVENING'];
  
    return (
      <div className="firstrow" style={{ display: "flex", gap: "1vw" }}>
        {allSessions.map((session) => (
          <button
            key={session}
            onClick={() => {
              console.log(`Clicked ${session} session`);
              setSelectedSession(session); // Update state
              setSelectedSlot(null); // Reset selected slot
  
              // Use the session directly for processing
              const currentDateSlots = bookedSlots[format(selectedDate, 'yyyy-MM-dd')] || sessions;
              console.log("Current Date Slots for Processing:", currentDateSlots);
  
              if (currentDateSlots && currentDateSlots.length > 0) {
                processAvailableSlots(currentDateSlots, session); // Pass session directly
              } else {
                console.warn("No slots available for processing");
                setAvailableSlots([]);
              }
            }}
            style={{
              backgroundColor: selectedSession === session ? '#6bacaf' : '#ddd',
              padding: '8px 16px',
              borderRadius: '4px',
            }}
          >
            {session}
          </button>
        ))}
      </div>
    );
  };
  
  



  useEffect(() => {
    const loadDoctorLeaves = () => {
      const savedLeaves = JSON.parse(localStorage.getItem('leaveHistory')) || [];
      setDoctorLeaves(savedLeaves);
    };

    loadDoctorLeaves();
    // Set up event listener for leave updates
    window.addEventListener('leaveHistoryUpdated', loadDoctorLeaves);
    
    return () => {
      window.removeEventListener('leaveHistoryUpdated', loadDoctorLeaves);
    };
  }, []);
  

  const renderCalendarDays = () => {
    const startDate = startOfMonth(currentMonth);
    const endDate = endOfMonth(currentMonth);
    const startWeek = startOfWeek(startDate);
    const endWeek = endOfWeek(endDate);
    const days = eachDayOfInterval({ start: startWeek, end: endWeek });

    return days.map((day, index) => {
      const leaveStatus = getLeaveStatus(day);
      const isFullDayLeave = leaveStatus.isFullDay;
      const hasPartialLeave = leaveStatus.isOnLeave && !leaveStatus.isFullDay;
      
      return (
        <div
          key={index}
          onClick={() => !isFullDayLeave && handleDateClick(day)}
          className={`day ${
            isSameMonth(day, currentMonth) ? "" : "other-month"
          } ${isSameDay(day, selectedDate) ? "selected-day" : ""}`}
          style={{
            opacity: isFullDayLeave ? 0.5 : 1,
            cursor: isFullDayLeave ? 'not-allowed' : 'pointer',
            backgroundColor: isFullDayLeave ? '#ffebee' : 
                           hasPartialLeave ? '' : undefined,
            position: 'relative'
          }}
        >
          {format(day, "d")}
          {/* {hasPartialLeave && !isFullDayLeave && (
            <span 
              style={{ 
                position: 'absolute', 
                top: '2px', 
                right: '2px', 
                width: '6px', 
                height: '6px', 
                borderRadius: '50%', 
                backgroundColor: '#ff9800' 
              }} 
            />
          )} */}
        </div>
      );
    });
  };


  const users = JSON.parse(localStorage.getItem('user'));

    const currentClinicId = users?.current_clinic;



  const { authTokens, user } = useContext(AuthContext);

  const [appointmentType, setAppointmentType] = useState("IA");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [Patientid, setPatientid] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("M");
  const [year, setYear] = useState("");
  const [age, setAge] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [address, setAddress] = useState("");
  const [doctorname, setDoctorname] = useState("");
  const formRef = useRef();
//   const [height, setHeight] = useState(0);
//   const [weight, setWeight] = useState(0);





const resetForm = () => {
  setFirstName("");
  setLastName("");
  setPhoneNumber("");
  setEmail("");
  setYear("");
  setAge("");
  setMonth("");
  setDay("");
  setAppointmentType("IA");
  setGender("M");
  setSelectedDate(new Date());
};

  const calculateAge = (day, month, year) => {
    const today = new Date();
    const birthDate = new Date(year, month - 1, day);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  };



  

  const handleExternalSubmit = () => {
    if (validateForm()) {
      if (formRef.current) {
        const submitEvent = new Event('submit', { cancelable: true, bubbles: true });
        formRef.current.dispatchEvent(submitEvent);
      }
    } else {
      swal.fire({
        title: "Please Check Form Details",
        text: Object.values(formErrors).join('\n'),
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const [ newpatientid,  setnewpatientid] = useState(null);

  console.log(newpatientid)
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    
    // Validate age
    if(age < 0){
      swal.fire({
        title: "Invalid Date of Birth",
        text: "Please enter a valid date of birth",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setLoading(false);
      return;
    }
  
    if (!selectedSession) {
      swal.fire({
        title: "Session Not Selected",
        text: "Please select a session",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setLoading(false);
      return;
    }
  
    // Find the session info to get the actual session ID
    const sessionInfo = sessions.find(
      session => session.session_name === selectedSession
    );
  console.log("bmnbm",sessionInfo)
    if (!sessionInfo) {
      swal.fire({
        title: "Session Not Found",
        text: "Unable to find session details",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setLoading(false);
      return;
    }
  
    // Map session times to specific numeric values
    const getSessionTimeValue = (sessionName) => {
      switch(sessionName.toLowerCase()) {
        case 'morning': return 1;
        case 'afternoon': return 2;
        case 'evening': return 3;
        default: return null;
      }
    };
  console.log("jhk",selectedSession)
  console.log("id",sessionInfo.slot_id)
  console.log(getSessionTimeValue(selectedSession))

  const users = JSON.parse(localStorage.getItem('user'));
  const doctorId = users.is_receptionist ? selectedDoctorId : user.user_id;
  const appointmentData = {
    appointment_datetime: format(selectedDate, "yyyy-MM-dd"),
    type: appointmentType,
    doctor_id: doctorId,
    // "slot_id": sessionInfo.slot_id,
    patient_detail: {
      phone_number: phoneNumber,
      email: email,
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      date_of_birth: `${year}-${month}-${day}`,
      age: parseInt(age),
      address: address,
    },
    patient_id: newpatientid,
    session_id: sessionInfo.session_id,
    clinic:currentClinicId,
    
  };
  

    console.log("Final Appointment Data:", appointmentData);
    console.log("sessionInfo:", sessionInfo);

  
    try {

      const url = appointment ? `${config.API_BASE_URL}/api/v1/appointment/patient-booking/`:`${config.API_BASE_URL}/api/v1/appointment/add/`
      const response = await fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${authTokens?.access}`,
          },
          body: JSON.stringify(appointmentData),
        }
      );
  
      if (response.status === 201) {
        resetForm();
        swal.fire({
          title: "Appointment Added",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        const errorData = await response.json();
        console.error("Failed to save appointment", errorData);
        
        swal.fire({
          title: "Appointment Submission Failed",
          text: errorData.message || "Please try again",
          icon: "error",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      swal.fire({
        title: "Network Error",
        text: "Unable to submit appointment",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebarToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (day && month && year) {
      const birthDate = new Date(year, month - 1, day);
      const today = new Date();
      let calculatedAge = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        calculatedAge--;
      }
      setAge(calculatedAge);
    }
  }, [day, month, year]);


  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 600);
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    
    // Check required fields
    if (!firstName.trim()) {
      errors.firstName = "First name is required";
    }
    
    // if (!phoneNumber.trim()) {
    //   errors.phoneNumber = "Phone number is required";
    // }
    
    // Check if either age or complete DOB is provided
    if (!age && (!day || !month || !year)) {
      errors.age = "Either age or complete date of birth is required";
    }

    // Validate date of birth if provided
    if (day || month || year) {
      if (!day || !month || !year) {
        errors.dob = "Please complete all date of birth fields";
      } else if (calculateAge(day, month, year) < 0) {
        errors.dob = "Invalid date of birth";
      }
    }

    // Validate phone number format (basic example)
    if (phoneNumber && !/^\d{10}$/.test(phoneNumber.replace(/\D/g, ''))) {
      errors.phoneNumber = "Please enter a valid 10-digit phone number";
    }

    // Validate email format if provided
    if (email && !/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid email address";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const SubmitButton = () => (
    <div className="submit" style={{ justifyContent: "center",width:"100%"}}>
      {loading ? (
        <div className="loading-spinner mx-auto" ></div>
      ) : (
        <button 
          onClick={handleExternalSubmit}
          className="bookbtn"
        >
          Book Appointment
        </button>
      )}
    </div>
  );

  
 

  useEffect(() => {
    const fetchPatientDetails = async () => {
      if (!Patientid) return;
  
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/api/v1/appointment/user-appointment/${Patientid}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${authTokens?.access}`,
            },
          }
        );
        
        if (response.ok) {
          const data = await response.json();
        //   if (data.patient_id) {
        //     sessionStorage.setItem('Patient', JSON.stringify(data.patient_id));
        // }
          console.log(data)
          // Update form fields with the fetched data
          setFirstName(data.patient_details.first_name || "");
          setLastName(data.patient_details.last_name || "");
          setPhoneNumber(data.patient_details.phone_number || "");
          setEmail(data.patient_details.email || "");
          setGender(data.patient_details.gender || "M");
          setAddress(data.patient_details.address || "");
  
          // Set age or DOB
          if (data.patient_details.age) {
            setAge(data.patient_details.age);
            setDay("");
            setMonth("");
            setYear("");
          } else if (data.patient_details.date_of_birth) {
            const dob = new Date(data.patient_details.date_of_birth);
            setDay(dob.getDate().toString().padStart(2, "0"));
            setMonth((dob.getMonth() + 1).toString().padStart(2, "0"));
            setYear(dob.getFullYear().toString());
          }
        } else {
          console.error("Failed to fetch patient details.");
          swal.fire({
            title: "Error",
            text: "Failed to fetch patient details.",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    };
  
    fetchPatientDetails();
  }, [Patientid, authTokens]);
  
  
  console.log("vhvhj",appointment)
  useEffect(() => {
    if (appointment) {
  
      // setPatientId(appointment.patient.id);
      setFirstName(appointment.patient.first_name);
      setLastName(appointment.patient.last_name);
      setPhoneNumber(appointment.patient.phone_number);
      setEmail(appointment.patient.email);
      setGender(appointment.patient.gender);
      setAddress(appointment.patient.address);
      setAge(appointment.patient.age);
      setSelectedDoctorId(appointment.doctor.id);
      setnewpatientid(appointment.patient.id);
      
    }
  }, [appointment]);
    
  return (
    <div className="bookappointmentpage">
      <NavBar
        OpenSidebar={OpenSidebar}
        openSidebarToggle={openSidebarToggle}
        userName={userName}
      />
      <SideBar
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
        userName={userName}
      />
      <div className="form-content">
        <h2>Book New Appointment</h2>
        <h3>Add an appointment for a patient</h3>
        <form ref={formRef} onSubmit={handleSubmit}>
          <h4>Appointment Details</h4>
          <div className="firstrow">
            <div className="firstname">
              <label>Appointment type*</label>
              <select
                style={{ height: "55%" }}
                name="appointment_type"
                id="appointment_type"
                placeholder="Select Appointment Type"
                value={appointmentType}
                onChange={(e) => setAppointmentType(e.target.value)}
              >
                <option value="IA">New Consult</option>
                <option value="FA">Follow Up</option>
              </select>
            </div>
            <div className="firstname">
              <label>Patient Id</label>
              <input
  type="number"
  value={Patientid}
  placeholder="Enter Patient ID"
  disabled={appointmentType === "IA"}
  required={appointmentType === "FA"}
  style={{
    backgroundColor: appointmentType === "IA" ? "#f0f0f0" : "white",
    cursor: appointmentType === "IA" ? "not-allowed" : "text",
  }}
  onChange={(e) => setPatientid(e.target.value)}
/>

            </div>
          </div>
          <div className="firstrow">
            <div className="firstname">
              <label>First name*</label>
              <input
                type="text"
                placeholder="Enter Patient's first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="firstname">
              <label>Last name</label>
              <input
                type="text"
                placeholder="Enter Patient's Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="firstrow">
            <div className="firstname">
              <label>Phone Number*</label>
              <input
                type="text"
                placeholder="Enter Patient's Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>
            <div className="firstname">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter Patient's Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="firstrow">
            <div className="firstname">
              <label>D.O.B/Age*</label>
              <div
                style={{
                  width: "100%",
                  height: "55%",
                  gap: "1vw",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <input
                  type="text"
                  style={{ height: "100%" }}
                  name="day"
                  placeholder="Day"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  required={!age}
                />
                <input
                  type="text"
                  style={{ height: "100%" }}
                  name="month"
                  placeholder="Month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  required={!age}
                />
                <input
                  type="text"
                  style={{ height: "100%" }}  
                  name="year"
                  placeholder="Year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  required={!age}
                />
                <input
                  type="number"
                  style={{ height: "100%" }}
                  name="age"
                  placeholder="Age"
                  value={day && month && year ? calculateAge(day, month, year) : age}
                  onChange={(e) => setAge(e.target.value)}
                  required={!day || !month || !year}
                  disabled={day && month && year}
                  />
              </div>
            </div>
            <div className="firstname">
              <label>Gender</label>
              <select
                style={{ height: "55%" }}
                name="appointment_type"
                id="appointment_type"
                placeholder="Select Appointment Type"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="O">Others</option>
              </select>
            </div>
           
          </div>
          <div className="firstrow">
           
            <div className="firstname">
              <label>Address</label>
              <input type="text" value={address} placeholder="Enter Patient's Address" onChange={(e)=>setAddress(e.target.value)} />
            </div>
            {users.is_receptionist && (
  <div className="firstname">
    <label>Select Doctor*</label>
    <select id="doctor-select" onChange={handleDoctorChange} value={selectedDoctorId || ''}>
                <option value="" disabled>Select a doctor</option>
                {DoctorData.length > 0 ? (
                    DoctorData.map((doctorMapping) => (
                        <option key={doctorMapping.id} value={doctorMapping.doctor_data.id}>
                            {`${doctorMapping.doctor_data.first_name} ${doctorMapping.doctor_data.last_name}`}
                        </option>
                    ))
                ) : (
                    <option value="" disabled>No doctors available</option>
                )}
            </select>
  </div>
)}

          </div>
          {isWideScreen && <SubmitButton />}
        </form>
      </div>
      <div className="calendar-content">
        <div className="calender-container">
          <div className="date-picker">
            <div className="month-year">
              <button
                onClick={() => setCurrentMonth((prev) => subMonths(prev, 1))}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <FcPrevious />
              </button>
              <div className="current-month">
                {format(currentMonth, "MMMM yyyy")}
              </div>
              <button
                onClick={() => setCurrentMonth((prev) => addMonths(prev, 1))}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <FcNext />
              </button>
            </div>
            <div className="days-of-week">{renderDaysOfWeek()}</div>
            <div className="calendar-days">{renderCalendarDays()}</div>
            {/* {selectedDate && <div>Selected Date: {format(selectedDate, 'MMMM d, yyyy')}</div>} */}
          </div>
          <div className="clock-container">
          
            <h2>Select Session</h2>

            {loading ? (
        <h6>Loading sessions...</h6>
      ) : (
        <>
        <div className="d-flex w-100 ">
        {["Morning", "Afternoon", "Evening"].map((sessionName) => {
            const sessionInfo = sessions.find(
              (session) => session.session_name === sessionName
            );

            const isDisabled =
              sessionInfo &&
              sessionInfo.booked_patients >= sessionInfo.max_patients;

            return (
              <button
              key={sessionName}
              onClick={() => setSelectedSession(sessionName)}
              className="sessionbtn me-2"
              style={{
                width:"100%",
                backgroundColor: isDisabled
                  ? "lightcoral"
                  : selectedSession === sessionName
                  ? "#005f73"
                  : "#6bacaf",
                  border: "none",
                cursor: isDisabled ? "not-allowed" : "pointer",
                opacity: isDisabled ? 0.6 : 1,
              }}
              disabled={isDisabled}
              >
                {sessionName}
              </button>
            );
          })}
          
        </div>
        <div style={{ marginTop: "20px" }}>{renderSessionDetails()}</div>
        </>
      )}
            
          </div>
          <div className="mt-2">
          {!isWideScreen && <SubmitButton />}
          </div>
        </div>
      </div>

      
      
    </div>
  );
}


