import React, { useState, useEffect, useRef } from 'react';
import PopupForm from './PopupForm';
import useAxios from '../../utils/useAxios';
import { useNavigate } from "react-router-dom";
import NavBar from '../nav_sidebar/NavBar';
import SideBar from '../nav_sidebar/SideBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './admin.css';
import config from '../../config';

function DoctorPage() {
  const [userName, setUserName] = useState('');
  const axiosInstance = useAxios();
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const sidebarRef = useRef(null);
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showmapPopup, setShowmapPopup] = useState(false);
  const [receptionists, setReceptionists] = useState([]);
  const [selectedReceptionist, setSelectedReceptionist] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [clinicRequests, setClinicRequests] = useState([]);
  const navigate = useNavigate();

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get('auth/users/me/');
        setUserName(response.data.full_name);
      } catch (error) {
        console.error('Failed to fetch user data', error);
      }
    };

    const fetchClinics = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_BASE_URL}/api/v1/clinic-management/client-clinic-profile/`);
        setClinics(response.data);
        // Automatically select the first clinic
        if (response.data.length > 0) {
          setSelectedClinic(response.data[0].id);
        }
      } catch (error) {
        console.error('Failed to fetch clinics', error);
      }
    };

    fetchUserData();
    fetchClinics();

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebarToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [axiosInstance]);


  const fetchDoctors = async () => {
    try {
      const response = await axiosInstance.get(`${config.API_BASE_URL}/api/v1/clinic-management/doctors-in-clinic/?clinic_id=${selectedClinic}`);
      const formattedDoctors = response.data.map((doctor) => ({
        id: doctor.id,
        'First Name': doctor.first_name,
        'Last Name': doctor.last_name,
        'Phone Number': doctor.phone_number,
        'Email': doctor.email,
        'Specialization': doctor.specialization_name || 'N/A',
      }));
      setDoctors(formattedDoctors);
    } catch (error) {
      console.error('Failed to fetch doctors', error);
    }
  };
  const fetchReceptionists = async () => {
    try {
      const response = await axiosInstance.get(`${config.API_BASE_URL}/api/v1/clinic-management/receptionist-clinic/?clinic_id=${selectedClinic}`);
      const activeReceptionists = response.data.filter(
        receptionist => receptionist.is_active && receptionist.clinic === selectedClinic
      );
      setReceptionists(activeReceptionists);
    } catch (error) {
      console.error('Failed to fetch receptionists', error);
    }
  };
  useEffect(() => {
    if (selectedClinic) {
      

      fetchDoctors();
     

      fetchReceptionists();
    }
  }, [selectedClinic, axiosInstance]);


 
  const handleMapReceptionist = async () => {
    if (selectedDoctor && selectedReceptionist) {
     

      try {
         console.log("idd",selectedClinic)
        await axiosInstance.post(`${config.API_BASE_URL}/api/v1/receptionist/doctor-receptionist/`, {
          clinic_id: selectedClinic,
          doctor_data: selectedDoctor,
          receptionist_data: selectedReceptionist
        });
        alert('Receptionist successfully mapped to the doctor.');
        setShowmapPopup(false)
      } catch (error) {
        console.error('Error mapping receptionist:', error);
        alert(error.response?.data?.message || 'Failed to map receptionist');
      }
    }
  };

  const handleAddOrEdit = async (doctorData) => {
    try {
      if (editIndex !== null) {
        // Handle edit case
        const response = await axiosInstance.put(`/api/v1/auth/doctor/${doctors[editIndex].id}/`, doctorData);
        const updatedList = [...doctors];
        updatedList[editIndex] = {
          ...response.data,
          'First Name': response.data.first_name,
          'Last Name': response.data.last_name,
          'Phone Number': response.data.phone_number,
          'Email': response.data.email,
          'Specialization': response.data.specialization_name
        };
        setDoctors(updatedList);
      } else {
        // Handle add new doctor case
        const doctorDatas = {
          clinic_id: selectedClinic,
          first_name: doctorData['First Name'],
          last_name: doctorData['Last Name'],
          email: doctorData['Email'],
          phone_number: doctorData['Phone Number'],
          password: doctorData.password,
          specialization: doctorData.special,
        };

        const response = await axiosInstance.post(`${config.API_BASE_URL}/api/v1/clinic-management/add-doctor-clinic/`, doctorDatas);
        const newDoctor = {
          id: response.data.id,
          'First Name': response.data.first_name,
          'Last Name': response.data.last_name,
          'Phone Number': response.data.phone_number,
          'Email': response.data.email,
          'Specialization': response.data.specialization_name
        };
        setDoctors([...doctors, newDoctor]);
        await fetchDoctors()
      }
      setShowPopup(false);
      setEditIndex(null);
    } catch (error) {
      console.error('Error adding/editing doctor:', error);
      alert(error.response?.data?.message || 'Failed to save doctor');
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setShowPopup(true);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredDoctors = doctors.filter((doctor) => {
    const fullName = `${doctor['First Name']} ${doctor['Last Name']}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  useEffect(() => {
    if (selectedClinic) {
      const fetchClinicRequests = async () => {
        try {
          const response = await axiosInstance.get(`${config.API_BASE_URL}/api/v1/clinic-management/clinic-request-list/?clinic_id=${selectedClinic}`);

          console.log('Fetched data:', response.data);
          setClinicRequests(response.data.results);
        } catch (error) {
          console.error('Failed to fetch clinic requests', error);
        }
      };
  
      fetchClinicRequests();
    }
  }, [selectedClinic, axiosInstance]);
  console.log("hghj",clinicRequests)
  const handleRequestAction = async (requestId, action) => {
    try {
      const response = await axiosInstance.put(`${config.API_BASE_URL}/api/v1/clinic-management/clinic-request-list/`, {
        clinic_id: selectedClinic,
        request_id: requestId,
        action: action
      });
      // Refresh the requests list after action
      if (response.status === 200) {
        // Refresh the requests list after action
        const updatedResponse = await axiosInstance.get(`${config.API_BASE_URL}/api/v1/clinic-management/clinic-request-list/?clinic_id=${selectedClinic}`);
        setClinicRequests(updatedResponse.data);
        
        alert(`Request ${action}ed successfully`);
      } else {
        alert(`Failed to ${action} request: ${response.data.message}`);
      }
    } catch (error) {
      console.error(`Failed to ${action} request`, error);
      alert(error.response?.data?.message || `Failed to ${action} request`);
    }
  };
  const handleDelete = async (doctorId) => {
    try {
      // Make API call to delete doctor
      await axiosInstance.delete(`${config.API_BASE_URL}/api/v1/clinic-management/doctor-delete/${doctorId}/`);
      
      // After successful deletion, refresh the doctors list
      await fetchDoctors();
      
      // Show success message
      alert('Doctor deleted successfully');
    } catch (error) {
      console.error('Failed to delete doctor:', error);
      alert(error.response?.data?.message || 'Failed to delete doctor');
    }
  };
  return (
    <div>
      <NavBar OpenSidebar={OpenSidebar} openSidebarToggle={openSidebarToggle} userName={userName} />
      <SideBar ref={sidebarRef} openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <div className="px-5 mt-4">
        <div className="d-flex justify-content-between">
          <p className="headings">List of Doctors</p>
          <div className='d-flex'>
          <button className="addbtn me-2" onClick={() => setShowPopup(true)}>
            Add Doctor
          </button>
          <button className="edit-btn" onClick={() => navigate("/admin")}>
            Back
          </button>
          </div>
        </div>

        <div className="d-flex mb-3">
          <input
            type="text"
            className="searchbar mt-2"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={handleSearchChange}
          />
           <select
            className="searchbar"
            value={selectedClinic || ''}
            onChange={(e) => setSelectedClinic(e.target.value)}
          >
            {clinics.map((clinic) => (
              <option key={clinic.id} value={clinic.id}>
                {clinic.clinic_name}
              </option>
            ))}
          </select>
          
        </div>

        {showPopup && (
          <PopupForm
            fields={['First Name', 'Last Name', 'Phone Number', 'Email']}
            initialValues={editIndex !== null ? doctors[editIndex] : {}}
            onSubmit={handleAddOrEdit}
            onClose={() => {
              setShowPopup(false);
              setEditIndex(null);
            }}
            popupname="Doctor"
            showspecialization={true}
          />
        )}

{showmapPopup && (
          <div className="popup">
            <div className="popup-inner" style={{background:"white",width:"35vw", padding:"12px",borderRadius:"10px"}}>
              <h4 >Map Receptionist to Doctor</h4>
              <select
                className="form-select"
                value={selectedReceptionist || ''}
                onChange={(e) => setSelectedReceptionist(e.target.value)}
              >
                <option value="">Select Receptionist</option>
                {receptionists.map((receptionist) => (
                  <option key={receptionist.id} value={receptionist.receptionist.id}>
                    {receptionist.receptionist.first_name}
                  </option>
                ))}
              </select>
              <button className="btn edit-btn" onClick={handleMapReceptionist}>
                Save Mapping
              </button>
              <button className="btn edit-btn" onClick={() => setShowmapPopup(false)}>
                Cancel
              </button>
            </div>
          </div>
        )}

{/* Add this before the final closing div */}
{/* Clinic Requests Section */}
<div className="mt-5">
  <p className="headings">Clinic Requests</p>
  <div className="table-responsive">
    <table className="table table-striped table-hover table-bordered">
      <thead className="table-header">
        <tr>
          <th>Sl No.</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone Number</th>
          <th>Actions</th>
        </tr>
      </thead>
      {clinicRequests.length > 0 ? (
        <tbody>
          {clinicRequests.map((request, index) => (
            <tr key={request.id}>
              <td>{index + 1}</td>
              <td>{request.doctor_first_name} {request.doctor_last_name}</td>
              <td>{request.doctor_email}</td>
              <td>{request.doctor_phone_number}</td>
              <td>
                <button
                  className="btn edit-btn me-2"
                  onClick={() => handleRequestAction(request.id, 'accept')}
                >
                  Accept
                </button>
                <button
                  className="btn delete-btn"
                  onClick={() => handleRequestAction(request.id, 'decline')}
                >
                  Decline
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td colSpan="5" className="text-center">
              No pending requests available.
            </td>
          </tr>
        </tbody>
      )}
    </table>
  </div>
</div>
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered">
            <thead className="table-header">
              <tr>
                <th>Sl No.</th>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Specialization</th>
                <th>Actions</th>
              </tr>
            </thead>
            {filteredDoctors.length > 0 ? (
              <tbody>
                {filteredDoctors.map((d, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{d['First Name']} {d['Last Name']}</td>
                    <td>{d['Phone Number']}</td>
                    <td>{d['Email']}</td>
                    <td>{d['Specialization']}</td>
                    <td>
                      <button
                        className="btn edit-btn"
                        onClick={() => {
                          setSelectedDoctor(d.id);
                          setShowmapPopup(true);
                        }}
                      >
                      Map Receptionist
                      </button>
                      <button
                        className="btn delete-btn"
                        onClick={() => handleDelete(d.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="6" className="text-center">
                    No doctors available.
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

export default DoctorPage;
