import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import NavBar from '../components/nav_sidebar/NavBar';
import SideBar from '../components/nav_sidebar/SideBar';
import { FaUserCircle } from "react-icons/fa";
import { ImBlocked } from "react-icons/im";
import useAxios from '../utils/useAxios';
import { useNavigate } from 'react-router-dom';

function ReceptionistAppointmentList() {
  const [userName, setUserName] = useState('John Doe');
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [rescheduleData, setRescheduleData] = useState(null);

  const axiosInstance = useAxios();
  const sidebarRef = useRef(null);

  const navigate = useNavigate();

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user information
        const userResponse = await axiosInstance.get('auth/users/me/');
        setUserName(userResponse.data.full_name);

        const users = JSON.parse(localStorage.getItem('user'));
        const currentClinicId = users?.current_clinic;

        // Fetch appointment list
        const appointmentsResponse = await axiosInstance.get(`api/v1/clinic-management/clinic-requested-appointment/?clinic_id=${currentClinicId}`);
        setAppointments(appointmentsResponse.data.results);
      } catch (error) {
        setError('Failed to fetch appointments.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebarToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [axiosInstance]);

  const handleReject = async (id) => {
    if (window.confirm("Are you sure you want to reject this appointment?")) {
      try {
        // Send DELETE request to the API
        await axiosInstance.delete(`api/v1/clinic-management/clinic-requested-appointment/${id}/`);
        
        // Update the state to remove the rejected appointment from the list
        setAppointments((prevAppointments) =>
          prevAppointments.filter(appointment => appointment.id !== id)
        );
  
        console.log(`Appointment ${id} rejected.`);
      } catch (error) {
        console.error(`Failed to reject appointment ${id}:`, error);
        alert('Failed to reject the appointment. Please try again.');
      }
    }
  };
  

  const handleReschedule = (appointment) => {
    navigate('/doc/bookappointment', { state: { appointment } });
  };

  const closeReschedulePopup = () => {
    setRescheduleData(null);
  };

  return (
    <>
      <NavBar
        OpenSidebar={OpenSidebar}
        openSidebarToggle={openSidebarToggle}
        userName={userName}
      />
      <SideBar
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />

      <div className="main-container">
        <h3>Appointments List</h3>
        <p>Preview and Approve Online Appointments</p>

        {loading ? (
          <div className='list-box'>
            <h6>Loading appointments...</h6>
          </div>
        ) : error ? (
          <div className='list-box'>
            <h6>{error}</h6>
          </div>
        ) : appointments.length > 0 ? (
          <div className="container-list">
            {appointments.map((appointment) => (
              <div key={appointment.id} className="appointment-card">
                <div className="text-container">
                  <h4>Request ID: {appointment.id}</h4>
                  <p>Created At: {new Date(appointment.created_at).toLocaleString()}</p>
                </div>

                <div>
                  <h4>
                    <FaUserCircle className="user-icon img-fluid" />
                    {appointment.patient.first_name} {appointment.patient.last_name}
                  </h4>
                  <p>Age: {appointment.patient.age}, Gender: {appointment.patient.gender}</p>
                  <p>Email: {appointment.patient.email}</p>
                  <p>Address: {appointment.patient.address}</p>
                </div>

                <div>
                  <p>
                    <FaUserCircle className="user-icon img-fluid" />
                    Dr. {appointment.doctor.first_name} {appointment.doctor.last_name}
                  </p>
                  <p>Phone: {appointment.doctor.phone_number}</p>
                  <p>Email: {appointment.doctor.email}</p>
                </div>

                <div className="m-2">
                  <button
                    className="btns btns-reject d-flex"
                    onClick={() => handleReject(appointment.id)}
                  >
                    <ImBlocked className='m-1' /> Reject
                  </button>
                </div>

                <div className="m-2">
                  <button
                    className="btns btns-reschedule d-flex"
                    onClick={() => handleReschedule(appointment)}
                  >
                    Schedule
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='list-box'>
            <h6>No appointments available</h6>
          </div>
        )}
      </div>

      {rescheduleData && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Reschedule Appointment</h3>
            <p>Reschedule appointment for {rescheduleData.patient.first_name} {rescheduleData.patient.last_name}</p>
            <button onClick={closeReschedulePopup}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default ReceptionistAppointmentList;
