/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import config from "../../config";
import "./style.css";
import { FaArrowRightLong } from "react-icons/fa6";
import ConsultationContext from "../../context/ConsultationContext";
import { TiTick } from "react-icons/ti";
import useAxios from "../../utils/useAxios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthContext from "../../context/AuthContext";
import axios from "axios";

const baseURL = `${config.API_BASE_URL}`;

const PatientRecord = ({
  AppointmentId,
  PatientName,
  AppointmentStatus,
  user,
  PatientAge,
  patientGender,
  
}) => {
  const [records, setRecords] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [pdfBlobs, setPdfBlobs] = useState({});
  const axiosInstance = useAxios();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [prevpdfUrl, setPrevPdfUrl] = useState(null);
  const [Prevappoinment, setPrevappoinment] = useState(null);
  const [billingPdfUrl, setBillingPdfUrl] = useState(''); 
  const { authTokens } = useContext(AuthContext);



  const getDisplayAge = (age) => {
      if (!age) return 'Not specified';
    
      const formatAge = (years, months) => {
        years = parseInt(years, 10);
        months = parseInt(months, 10);
        const yearText = years === 1 ? 'year' : 'years';
        const monthText = months === 1 ? 'month' : 'months';
    
        if (months > 0) {
          return `${years} ${yearText} ${months} ${monthText}`;
        } else {
          return `${years} ${yearText}`;
        }
      };
    
      // Handle age in the format "30-5-0" (years-months-days)
      if (typeof age === 'string' && age.includes('-')) {
        const [years, months] = age.split('-');
        return formatAge(years, months);
      }
    
      // Handle age as a float (e.g., 30.5)
      if (typeof age === 'number' || (typeof age === 'string' && !isNaN(parseFloat(age)))) {
        const ageStr = age.toString();
        let [years, months] = ageStr.split('.');
    
        // Handle cases where no decimal part exists
        months = months ? months.substring(0, 2) : '0';
        months = Math.round((parseInt(months) / Math.pow(10, months.length)) * 12);
    
        return formatAge(years, months);
      }
    
      // Default case: return the age as is
      return age;
    };
    
  
    const displayAge = getDisplayAge(PatientAge);
  
    useEffect(() => {
      console.log("Patient Age Prop:", PatientAge);
    }, [PatientAge]);
  
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUploadClick = async () => {
    if (selectedFiles) {
      const formData = new FormData();
      Array.from(selectedFiles).forEach((file) => {
        formData.append("files", file);
      });

      try {
        const response = await axiosInstance.post(
          `api/v1/patient-records/upload/${AppointmentId}/`,
          formData
        );
        console.log(response);
        if (response.status === 201) {
          console.log("Files uploaded successfully");
          fetchRecords(); // Refresh the records after upload
        } else {
          console.error("Failed to upload files");
        }
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    } else {
      console.log("No files selected");
    }
  };

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/v1/consultation/prescriptions/${AppointmentId}/`,
          {
            responseType: "blob", // Ensure Axios handles this as a blob
          }
        );
        if (response.status === 200) {
          const url = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          setPdfUrl(url);
        } else {
          setPdfUrl(null);
        }
      } catch (error) {
        // console.error("Error fetching PDF:", error);
        setPdfUrl(null);
      }
    };

    fetchPdf();
  }, [AppointmentId, axiosInstance]);

  useEffect(() => {
  const fetchPrevappoinment = async () => {
    try {
      const response = await axiosInstance.get(
            `/api/v1/appointment/user-appointment/${AppointmentId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authTokens?.access}`,
        },
      });
      setPrevappoinment(response.data.
        previous_appointment
        );
    } catch (error) {
      console.error('Error fetching findings:', error);
    }
  };

  fetchPrevappoinment()
}, [AppointmentId, axiosInstance]);
console.log("hghghgghghg",Prevappoinment)

useEffect(() => {
  const fetchprevPdf = async () => {
    
    console.log("Fetching previous PDF for appointment ID:", Prevappoinment);

    try {
      const response = await axiosInstance.get(
        `/api/v1/consultation/prescriptions/${Prevappoinment}/`,
        {
          responseType: "blob", // Ensure Axios handles this as a blob
        }
      );

      if (response.status === 200) {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        setPrevPdfUrl(url);
        console.log("Previous PDF fetched successfully:", url);
      } else {
        console.error("Failed to fetch previous PDF. Status code:", response.status);
        setPrevPdfUrl(null);
      }
    } catch (error) {
      console.error("Error fetching previous PDF:", error);
      setPrevPdfUrl(null);
    }
  };

  fetchprevPdf();
}, [Prevappoinment, axiosInstance]);


  const fetchRecords = async () => {
    try {
      const response = await axiosInstance.get(
        `api/v1/patient-records/list/${AppointmentId}/`
      );
      if (response.status === 200) {
        setRecords(response.data);
        
        // Fetch PDFs and create blob URLs
        const pdfPromises = response.data
        .filter((record) => record.file.endsWith(".pdf"))
        .map(async (record) => {
          try {
            const fileName = record.file.split("/").pop();
            const fileResponse = await axiosInstance.get(
              `/api/v1/patient-records/record/${AppointmentId}/${fileName}/`,
              {
                responseType: "blob", // Handling response as a blob
              }
            );
            if (fileResponse.status === 200) {
              const url = URL.createObjectURL(fileResponse.data);
              console.log(url);
              setPdfBlobs((prev) => ({ ...prev, [fileName]: url }));
            } else {
              console.error(`Failed to fetch PDF: ${fileName}`);
              setPdfBlobs(null)
            }
          } catch (error) {
            setPdfBlobs(null)
            console.error(`Error fetching PDF`, error);
          }
        });
        
        await Promise.all(pdfPromises);
      } else {
        console.log("Failed to fetch records");
        setRecords(null);
      }
    } catch (error) {
      console.log("Error fetching records:", error);
    }
  };

  const fetchBillingPdf = async () => {
    try {
      console.log('Fetching billing PDF for AppointmentId:', AppointmentId);  // Debug log
      const response = await fetch(
        `${config.API_BASE_URL}/api/v1/billing-app/billing/${AppointmentId}/pdf/`,
        {
          method: "GET",
          headers: {
            "Authorization": `JWT ${authTokens?.access}`, // Add your auth token
          },
        }
      );
      console.log("Billing response status:", response.status);  // Log the response status
  
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setBillingPdfUrl(url);
        console.log("Billing PDF URL:", url);  // Log the created URL
      } else {
        console.log('Billing PDF request failed with status:', response.status);  // Log failure status
        setBillingPdfUrl(null);
      }
    } catch (error) {
      console.error('Error fetching Billing PDF:', error);
    }
  };
  
  

  
  useEffect(() => {
    fetchRecords();
    fetchBillingPdf();
  }, [AppointmentId]);

  const { CreateConsultation } = useContext(ConsultationContext);

  const handleClick = async () => {
    try {
      await CreateConsultation(AppointmentId);
    } catch (error) {
      console.log(error);
    }
  };

  // Settings for the React Slick slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <>
    <div className="top-patient-info">
    <span>
      Gender:{" "}
      <span className="top-patient-info-vars">{patientGender}</span>
    </span>
    <span>
      Age:{" "}
      <span className="top-patient-info-vars">
        {/* {Math.floor(PatientAge)} years */}
        {displayAge}
      </span>
    </span>
  </div>
  <div className="record">
  <div className="info">
    {records.length > 0 || pdfUrl || billingPdfUrl || prevpdfUrl ? (
      <Slider {...settings} >
        {pdfUrl && (
          <div className="pdf-container">
            <embed
              src={pdfUrl}
              type="application/pdf"
              className="pdf-viewer"
            />
          </div>
        )}
        {prevpdfUrl && (
          <div className="pdf-container">
            <embed
              src={prevpdfUrl}
              type="application/pdf"
              className="pdf-viewer"
            />
          </div>
        )}
        {records.map((record, index) => {
          const fileName = record.file.split("/").pop();
          return (
            <div key={index}>
              {record.file.endsWith(".pdf") ? (
                <div className="pdf-container">
                  <embed
                    src={pdfBlobs[fileName] || ""}
                    type="application/pdf"
                    className="pdf-viewer"
                  />
                </div>
              ) : (
                <img
                  src={`${config.API_BASE_URL}/api/v1/patient-records/record/${AppointmentId}/${fileName}`}
                  alt="Patient Record"
                  className="image-viewer"
                />
              )}
            </div>
          );
        })}

{billingPdfUrl && (
          <div className="pdf-container">
            <embed
              src={billingPdfUrl}
              type="application/pdf"
              className="pdf-viewer"
            />
          </div>
        )}
      </Slider>
    ) : (
      <div className="record">
        <div style={{ fontSize: "1.3vw", textAlign: "center" }}>
          No Records Found
        </div>
      </div>
    )}
  </div>

  <div className="patientrecord-button">
    {user && (
      <div className="file-upload-container">
        <input
          type="file"
          onChange={handleFileChange}
          className="file-input"
          multiple
        />
        <button onClick={handleUploadClick} className="upload-button-meow">
          Upload
        </button>
        {user.is_doctor === true ? (
          AppointmentStatus === "CL" ? (
            <button
              className="completed-button hoverbutton"
            >
              Completed <TiTick />
            </button>
          ) : (
            <button className="proceed-button hoverbutton" onClick={handleClick}>
              Proceed <FaArrowRightLong />
            </button>
          )
        ) : null}
      </div>
    )}
  </div>
</div>

    </>
  );
};

export default PatientRecord;
