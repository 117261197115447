import React, { useEffect, useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import LoginPage from './pages/LoginPage.js';
import SignupPage from './pages/SignupPage.js';
import HomePage from './pages/HomePage.js';
import NewConsult from './pages/NewConsult.js';
import BillingPage from './pages/BillingPage.js';
import RolePage from './pages/RolePage.js';
import SettingsPage from './pages/settingsPage.js';
import DoctorSetupPage from './pages/DoctorSetupPage.js';
import PrivateRoute from './utils/PrivateRoute.js';
import { AuthProvider } from './context/AuthContext.js';
import DocProfile from './pages/DocProfile.js';
import { ConsultationProvider } from './context/ConsultationContext.js';
import BookAppointment from './pages/BookAppointment.js';
import ReceptionistAddPage from './pages/ReceptionistAddPage.js';
import ProfileUpdate from './components/profilepagecomponents/ProfileUpdate.jsx';
import LandingPage from './pages/LandingPage.js';
// import CheckRoute from './utils/CheckRoute.js';
import ProfileSetup from './pages/ProfileSetup.js';
import ClinicInfo from './pages/ClinicInfo.js';
import Receptionistsignup from './pages/Receptionistsignup.js';
import ReceptionistHomePage from './pages/ReceptionistHomePage.js';
import ReceptionistBookAppointment from './pages/ReceptionistBookAppointment.js';
import Todopage from './pages/Todopage.js';
import ReceptionistAppointmentList from './pages/ReceptionistAppointmentList.js';
import ProfilePage from './pages/ReceptionistProfile.js';
import AppointmentCardContent from './components/profilepagecomponents/AppointmentCardContent.jsx';
import Accountsettings from './pages/settingsPage.js';
import Dashboard from './components/admin/Dashboard.js';
import ReceptionistPage from './components/admin/ReceptionistPage.js';
import DoctorPage from './components/admin/DoctorPage.js';
import ClinicPage from './components/admin/ClinicPage.js';
import AdminSignup from './pages/AdminSignup.js';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

 
  useEffect(() => {
    const tokens = localStorage.getItem("authTokens");
    if (tokens) {
      setIsAuthenticated(true); // User is authenticated
    } else {
      setIsAuthenticated(false); // User is not authenticated
    }
  }, []);
  return (
        <Router>
          {/* <Routes>
            <Route path="/" element={<LandingPage />}/>
          </Routes> */}
        <AuthProvider>
        <ConsultationProvider>
          <Routes>
          <Route 
              path="/" 
              element={isAuthenticated ? <Navigate to="/doc/home" /> : <LandingPage />} 
            />
              <Route path="/doc/signup" element={<SignupPage />}/>
              <Route path="/rec/receptionistsignup" element={<Receptionistsignup />}/>
              <Route path="/doc/login" element={<LoginPage />} />
              <Route path="/doc/doc-details" element={<PrivateRoute><DoctorSetupPage /></PrivateRoute>} exact/>
              <Route path="/doc/home" element={<PrivateRoute><HomePage /></PrivateRoute>} exact/>
              <Route path="/receptionisthome" element={<ReceptionistHomePage/>}/>
              <Route path="/doc/newconsult" element={<PrivateRoute><NewConsult/></PrivateRoute>} exact/>
              <Route path="/doc/appointment-process" element={<PrivateRoute><AppointmentCardContent/></PrivateRoute>} />
              <Route path="/doc/bill" element={<PrivateRoute><BillingPage/></PrivateRoute>} exact/>
              <Route path="/doc/bookappointment" element={<PrivateRoute><BookAppointment /></PrivateRoute>} exact/>
              <Route path="/receptionistbookappointment" element={<ReceptionistBookAppointment/>}/>
              <Route path="/doc/receptionistadd" element={<PrivateRoute><ReceptionistAddPage /></PrivateRoute>} exact/>
              <Route path="/doc/doc-profile" element={<PrivateRoute><DocProfile/></PrivateRoute>} />
              <Route path="/doc/profile-setup" element={<PrivateRoute><ProfileSetup/></PrivateRoute>} />
              <Route path="/doc/profile-update" element={<PrivateRoute><ProfileUpdate/></PrivateRoute>} />
              <Route path="/doc/settings" element={<PrivateRoute><SettingsPage/></PrivateRoute>} />
              <Route path="/doc/edit-clinic" element={<PrivateRoute><ClinicInfo/></PrivateRoute>} />
              <Route path="/doc/role" element={<RolePage />} exact/>
              <Route path="/doc/todopage" element={<Todopage/>}/>
              <Route path="/receptionistappointmentrequest" element={<ReceptionistAppointmentList/>}/>
              <Route path='/receptionistprofilepage' element={<ProfilePage/>}/>
              <Route path='/doc/setting' element={<PrivateRoute><Accountsettings/></PrivateRoute>} />
              <Route path='/admin' element={<Dashboard/>}></Route>
             <Route path='/receptionists' element={<ReceptionistPage/>}></Route>
             <Route path='/doctor' element={<DoctorPage/>}></Route>
             <Route path='/clinic' element={<ClinicPage/>}></Route>
             <Route path='/admin-signup' element={<AdminSignup/>}></Route>
          </Routes>
        </ConsultationProvider>
        </AuthProvider>
        </Router>
  );
}

export default App;



