
import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import config from '../../config';
import Select from "react-select/creatable"; 
import { IoIosCloseCircle } from "react-icons/io";

function PopupForm({
  fields,
  initialValues,
  onSubmit,
  onClose,
  doctors,
  receptionists,
  showDoctorReceptionistCheckbox,
  showspecialization,
  popupname,
}) {
  const [formData, setFormData] = useState(initialValues || {});
  const [showDoctorReceptionist, setShowDoctorReceptionist] = useState(initialValues?.doctorReceptionistPairs?.length > 0);
  const [doctorReceptionistPairs, setDoctorReceptionistPairs] = useState(initialValues?.doctorReceptionistPairs || []);
  const [clinicList, setClinicList] = useState([]);
  const { authTokens } = useContext(AuthContext);
  const [specializations, setSpecializations] = useState([]);
   const [selectedSpecialization, setSelectedSpecialization] = useState(null);

  useEffect(() => {
    // Fetch clinic list from API
    axios.get(`${config.API_BASE_URL}/api/v1/clinic-management/client-clinic-profile/`,{
      headers: {
        Authorization: `JWT ${authTokens?.access}`,
      }
    })
     
      .then(response => {
        setClinicList(response.data); // Assuming the data is an array of clinics
      })
      .catch(error => {
        console.error('Error fetching clinic list:', error);
      });

    // Reset form data and doctor-receptionist pairs on initialValues change
    setFormData(initialValues || {});
    setShowDoctorReceptionist(initialValues?.doctorReceptionistPairs?.length > 0);
    setDoctorReceptionistPairs(initialValues?.doctorReceptionistPairs || []);
  }, [initialValues]);


  useEffect(() => {
    // Fetch all the specializations
    fetch(`${config.API_BASE_URL}/api/v1/specialization/`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        const formattedSpecializations = data.map((specialization) => ({
          value: specialization.id,
          label: specialization.name,
        }));
        setSpecializations(formattedSpecializations);
        console.log(specializations)
      })
      .catch((error) => {
        console.error("Error fetching specializations:", error);
      });
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setShowDoctorReceptionist(e.target.checked);
  };

  const handleDoctorReceptionistChange = (index, type, value) => {
    setDoctorReceptionistPairs((prevPairs) => {
      const updatedPairs = [...prevPairs];
      updatedPairs[index] = {
        ...updatedPairs[index],
        [type]: value,
      };
      return updatedPairs;
    });
  };
 
  
  const special = selectedSpecialization?.value
 
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData, doctorReceptionistPairs,special});
  };

  const getFieldRows = () => {
    const rows = [];
    for (let i = 0; i < fields.length; i += 2) {
      rows.push(fields.slice(i, i + 2)); // Group two fields per row
    }
    return rows;
  };

  const handleAddPair = () => {
    const lastPair = doctorReceptionistPairs[doctorReceptionistPairs.length - 1];

    if (lastPair) {
      if (lastPair.doctor || lastPair.receptionist) {
        setDoctorReceptionistPairs([
          ...doctorReceptionistPairs,
          { doctor: '', receptionist: '' },
        ]);
      } else {
        alert("Please select at least one field (Doctor or Receptionist) before adding another pair.");
      }
    } else {
      setDoctorReceptionistPairs([{ doctor: '', receptionist: '' }]);
    }
  };

  return (
    <div className="popupOverlay">
      <div className="popupContainer">
        <form className="popupForm" onSubmit={handleSubmit}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 className="headings">{popupname} Details</h2>
            
           <IoIosCloseCircle onClick={onClose} style={{ color: "#005F73", width: "25px", height: "30px" }} />
          </div>

          {getFieldRows().map((row, index) => (
            <div key={index} className="formRow">
              {row.map((field, i) => (
                <div key={i} className="formGroup">
                  <label className="formLabel">{field}</label>
                  <input
                    type="text"
                    name={field}
                    className="formInput"
                    value={formData[field] || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              ))}
            </div>
          ))}

{!showDoctorReceptionistCheckbox && (
  <>
          <div className="formRow">
            <div className="formGroup">
              <label className="formLabel">Password</label>
              <input
                type="password"
                name="password"
                className="formInput"
                value={formData['password'] || ''}
                onChange={handleChange}
                required
              />
            </div>
            <div className="formGroup">
              <label className="formLabel">Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                className="formInput"
                value={formData['confirmPassword'] || ''}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="formRow">
            <>
          <div className="formGroup">
            <label className="formLabel">Select Clinic</label>
            <select
              name="clinic_id"
              className="formInput"
              value={formData['clinic_id'] || ''}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Clinic</option>
              {clinicList.map((clinic, index) => (
                <option key={index} value={clinic.id}>{clinic.clinic_name}</option>
              ))}
            </select>
          </div>
          {showspecialization && (
            <div className="formGroup">
            <label className="formLabel mb-2">Select Specialization</label>
            <Select
                options={specializations}
                onChange={setSelectedSpecialization}
                placeholder="Search and select specialization"
                 className="formInput"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    height: "46.5px",
                   
                  }),
                }}
              />
          </div>
          )}
          
          </>
          </div>

          </>
             )}

          {/* Doctor and Receptionist Checkbox and Pairing Section */}
          {/* {showDoctorReceptionistCheckbox && (
            <>
              <div className="checkboxGroup">
                <label style={{ fontWeight: "500" }}>
                  Assign Doctors and Receptionists
                </label>
                <div className="mx-1 mb-1 ">
                  <input
                    type="checkbox"
                    checked={showDoctorReceptionist}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>

              {showDoctorReceptionist && (
                <div className="pairContainer">
                  {doctorReceptionistPairs.map((pair, index) => (
                    <div key={index} className="pairRow">
                      <div>
                        <label className="dropdownLabel">Doctor</label>
                        <select
                          className="dropdown"
                          onChange={(e) =>
                            handleDoctorReceptionistChange(index, 'doctor', e.target.value)
                          }
                          value={pair.doctor || ""}
                        >
                          <option value="" disabled>
                            Select Doctor
                          </option>
                          {doctors.map((doctor, i) => (
                            <option key={i} value={doctor}>
                              {doctor}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label className="dropdownLabel">Receptionist</label>
                        <select
                          className="dropdown"
                          onChange={(e) =>
                            handleDoctorReceptionistChange(index, 'receptionist', e.target.value)
                          }
                          value={pair.receptionist || ""}
                        >
                          <option value="" disabled>
                            Select Receptionist
                          </option>
                          {receptionists.map((receptionist, i) => (
                            <option key={i} value={receptionist}>
                              {receptionist}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ))}

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="button"
                      className="addbtn py-2 px-3"
                      onClick={handleAddPair}
                    >
                      Add Doctor-Receptionist
                    </button>
                  </div>
                </div>
              )}
            </>
          )} */}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button type="submit" className="addbtn px-5 py-2 mt-2">
              Submit
            </button>
            
          </div>
        </form>
      </div>
    </div>
  );
}

export default PopupForm;
