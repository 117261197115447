



import React, { useState, useEffect, useContext } from "react";
import { Button, Table, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthContext from "../../context/AuthContext";
import config from "../../config";

const VaccinesCard = () => {
  const [vaccines, setVaccines] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingVaccineId, setEditingVaccineId] = useState(null);
  const [vaccineData, setVaccineData] = useState({
    name: "",
    dosageCount: "",
    dosageInterval: "",
    intervalUnit: "Days",
    price: "",
  });
  const { authTokens } = useContext(AuthContext);

  const API_BASE_URL = `${config.API_BASE_URL}`;
  const doctorData =JSON.parse(localStorage.getItem('user'));
  const doctorId = doctorData?.id;

  // Fetch vaccines from API
  const fetchVaccines = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/vaccine-app/vaccines/${doctorId}/`, {
        headers: { Authorization: `JWT ${authTokens?.access}` },
      });
      setVaccines(response.data);
      console.log(vaccines)
    } catch (error) {
      console.error("Error fetching vaccines:", error);
      // alert("Failed to fetch vaccines. Please try again.");
    }
  };

  useEffect(() => {
    fetchVaccines();
  }, []);

  // Toggle inline form visibility
  const toggleForm = () => {
    setShowForm(!showForm);
    setIsEditing(false); // Reset editing state when form is toggled
    setEditingVaccineId(null);
    setVaccineData({
      name: "",
      dosageCount: "",
      dosageInterval: "",
      intervalUnit: "Days",
      price: "",
    });
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVaccineData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission (POST or PUT data to API)
  const handleSubmit = async () => {
    const { name, dosageCount, dosageInterval, intervalUnit, price } = vaccineData;

    if (!name || !dosageCount || !price) {
      alert("Please fill all mandatory fields");
      return;
    }

    const postData = {
      doctor_id: doctorId,
      vaccine_name: name,
      vaccine_dosages: parseInt(dosageCount, 10),
      interval_type: intervalUnit.toLowerCase(),
      vaccine_interval: dosageInterval ? parseInt(dosageInterval, 10) : 0,
      vaccine_price: parseFloat(price),
    };

    try {
      if (isEditing) {
        // Edit existing vaccine
        await axios.put(`${API_BASE_URL}/api/v1/vaccine-app/vaccines/${editingVaccineId}/`, postData, {
          headers: { Authorization: `JWT ${authTokens?.access}` },
        });
        alert("Vaccine updated successfully!");
      } else {
        // Add new vaccine
        const response = await axios.post(`${API_BASE_URL}/api/v1/vaccine-app/vaccines/`, postData, {
          headers: { Authorization: `JWT ${authTokens?.access}` },
        });
        setVaccines((prevVaccines) => [...prevVaccines, response.data]);
        alert("Vaccine added successfully!");
      }

      fetchVaccines();
      toggleForm();
    } catch (error) {
      console.error("Error saving vaccine:", error);
      alert("Failed to save vaccine. Please try again.");
    }
  };

  // Handle vaccine deletion
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/v1/vaccine-app/vaccines/${id}/`, {
        headers: { Authorization: `JWT ${authTokens?.access}` },
      });
      alert("Vaccine deleted successfully!");
      fetchVaccines();
    } catch (error) {
      console.error("Error deleting vaccine:", error);
      // alert("Failed to delete vaccine. Please try again.");
    }
  };

  // Handle vaccine edit
  const handleEdit = (vaccine) => {
    const dosageInfo = vaccine.dosages?.[0] || {};
    setIsEditing(true);
    setEditingVaccineId(vaccine.vaccine_id);
    setVaccineData({
      name: vaccine.vaccine_name,
      dosageCount: dosageInfo.vaccine_dosages || "",
      dosageInterval: dosageInfo.vaccine_interval || "",
      intervalUnit: dosageInfo.interval_type || "Days",
      price: vaccine.vaccine_price,
    });
    setShowForm(true);
  };

  return (
    <div className="container mt-4" style={{ overflow: "auto" }}>
      <h4>Vaccines for Patient</h4>

      {/* Display Saved Vaccines */}
      <Table striped bordered hover responsive className="mt-3">
        <thead>
          <tr>
            <th>Vaccine Name</th>
            <th>Price (₹)</th>
            <th>Dosages Count</th>
            <th>Interval</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {vaccines.length > 0 ? (
    vaccines.map((vaccine, index) => {
      const dosageInfo = vaccine.dosages?.[0] || {};
      return (
        <tr key={index}>
          <td>{vaccine.vaccine_name}</td>
          <td>₹{vaccine.vaccine_price}</td>
          <td>{dosageInfo.vaccine_dosages || "-"}</td>
          <td>
            {dosageInfo.vaccine_interval || " " } {dosageInfo.interval_type || "-"}
          </td>
          <td>
            <Button variant="warning" className="me-2" onClick={() => handleEdit(vaccine)}>
              Edit
            </Button>
            <Button variant="danger" onClick={() => handleDelete(vaccine.vaccine_id)}>
              Delete
            </Button>
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="5" className="text-center">
        No vaccines added yet.
      </td>
    </tr>
  )}
</tbody>

      </Table>

      {/* Add More Button */}
      <Button variant="primary" onClick={toggleForm} className="mt-3">
        {showForm ? "Cancel" : "Add More"}
      </Button>

      {/* Inline Form */}
      {showForm && (
        <div className="mt-3 p-3 border rounded">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Vaccine Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={vaccineData.name}
                onChange={handleInputChange}
                placeholder="Enter vaccine name"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Dosages Count</Form.Label>
              <Form.Control
                type="number"
                name="dosageCount"
                placeholder="Enter dosage count"
                onChange={handleInputChange}
                value={vaccineData.dosageCount}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Dosage Interval</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="number"
                  name="dosageInterval"
                  value={vaccineData.dosageInterval}
                  onChange={handleInputChange}
                  placeholder="Enter interval"
                  style={{ width: "60%" }}
                />
                <Form.Select
                  name="intervalUnit"
                  value={vaccineData.intervalUnit}
                  onChange={handleInputChange}
                  style={{ width: "40%", marginLeft: "10px" }}
                >
                  <option value="Days">Days</option>
                  <option value="Months">Months</option>
                </Form.Select>
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Price (₹)</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={vaccineData.price}
                onChange={handleInputChange}
                placeholder="Enter price"
                required
              />
            </Form.Group>

            <Button variant="success" onClick={handleSubmit} className="me-2">
              {isEditing ? "Update Vaccine" : "Save Vaccine"}
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default VaccinesCard;
