// import React, { useState, useEffect, useRef,useContext } from 'react';
// import { FaClinicMedical } from "react-icons/fa";
// import { IoIosCloseCircle } from "react-icons/io";
// import './style.css';
// import axios from 'axios';
// import AuthContext from '../../context/AuthContext';
// import config from '../../config';

// function Account() {
//   const [isSwitchClinicPopupOpen, setIsSwitchClinicPopupOpen] = useState(false);
//   const [isEditDetailsPopupOpen, setIsEditDetailsPopupOpen] = useState(false);
//   const switchClinicPopupRef = useRef(null);
//   const editDetailsPopupRef = useRef(null);
//   const { authTokens } = useContext(AuthContext);
//   const [activeClinic, setActiveClinic] = useState(null);

//   // Function to toggle the Switch Clinic popup
//   const toggleSwitchClinicPopup = () => {
//     setIsSwitchClinicPopupOpen(!isSwitchClinicPopupOpen);
//     setIsEditDetailsPopupOpen(false); // Close the other popup if open
//     setIsAddClinicFormVisible(false); // Hide the add form initially
//     if (!isSwitchClinicPopupOpen) fetchClinics(); 
//   };

//   // Function to toggle the Edit Details popup
//   const toggleEditDetailsPopup = () => {
//     setIsEditDetailsPopupOpen(!isEditDetailsPopupOpen);
//     setIsSwitchClinicPopupOpen(false); // Close the other popup if open
//   };

//   useEffect(() => {
//     if (!isSwitchClinicPopupOpen) fetchClinics();
//   }, [isSwitchClinicPopupOpen]);
//   // Close the popups when clicking outside of them
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (isSwitchClinicPopupOpen && switchClinicPopupRef.current && !switchClinicPopupRef.current.contains(event.target)) {
//         setIsSwitchClinicPopupOpen(false);
//       }
//       if (isEditDetailsPopupOpen && editDetailsPopupRef.current && !editDetailsPopupRef.current.contains(event.target)) {
//         setIsEditDetailsPopupOpen(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [isSwitchClinicPopupOpen, isEditDetailsPopupOpen]);


//   const [isAddClinicFormVisible, setIsAddClinicFormVisible] = useState(false);
//   const [clinicName, setClinicName] = useState("");
//   const [clinicAddress, setClinicAddress] = useState("");
//   const [phone, setPhone] = useState("");
//   const [timing, setTiming] = useState("");
//   const [clinics, setClinics] = useState([]);

 


//   const fetchClinics = async () => {
//     try {
//       const response = await axios.get(`${config.API_BASE_URL}/api/v1/clinic-management/client-clinic-profile/`,{
//         headers: {
//           Authorization: `JWT ${authTokens?.access}`,
//         },
//       }); // Replace with your API endpoint
    
//       setClinics(response.data);
//     } catch (error) {
//       console.error('Error fetching clinics:', error);
//     }
//   };


//   const handleClinicSwitch = async (clinicId) => {
//     try {
//       const response = await axios.post(`${config.API_BASE_URL}/api/v1/user/change-clinic/`, 
//         { clinic_id: clinicId }, 
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `JWT ${authTokens?.access}`,
//           },
//         });
//       setActiveClinic(response.data); // Update the active clinic based on response
//       localStorage.setItem('user', JSON.stringify(response.data));
//       alert('Clinic switched successfully!');
//       setIsSwitchClinicPopupOpen(false);
//     } catch (error) {
//       console.error('Error switching clinic:', error);
//     }
//   };
//   console.log(clinics)
//   const handleAddClinic = async () => {
//     try {
//       const newClinic = { clinic_name: clinicName,  clinic_address: clinicAddress,  contact: phone ,is_doctor_admin:true};
//       await axios.post(`${config.API_BASE_URL}/api/v1/clinic-management/clinic-profile/`, newClinic,
//         {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `JWT ${authTokens?.access}`,
//         },
//       }
//       ); // Replace with your API endpoint
//       alert('Clinic added successfully!');
//       fetchClinics();
//       setClinicName("");
//       setClinicAddress("");
//       setPhone("");
//       setIsAddClinicFormVisible(false); // Hide the form after adding
      
//     } catch (error) {
//       console.error('Error adding clinic:', error);
//     }
//   };
//   const handleSave = () => {
   
//   };
//   const userData = JSON.parse(localStorage.getItem('user'));
//   const currentClinicId = userData?.current_clinic;

//   return (
//     <div className='account-container'>
//       <div className='right-container'>
//         <h3>Account Setup</h3>
//         <p>User Information</p>
//         <div className='setup-cont'>
//           <img src='/user.png' alt='user' style={{ height: '140px', width: '130px', marginRight: '20px' }} />
//           <div>
//             <div className='d-flex'>
//             <div>
//           <li className='list my-3'>Email Id</li>
//           <li className='list my-3'>Password</li>
//           <li className='list my-3'>Phone No.</li>
//           </div>
//             <div className='input-fields'>
//             <input type='email' placeholder='email' className='inp' />
            
//             <input type='number' placeholder='Number' className='inp' />
            
//             <input type='number' placeholder='Number' className='inp' />
            

//             </div>
//             <div className='input-fields' style={{marginLeft:"5%"}}>
//             <div className='acc-btn'>CHANGE ACCOUNT</div>
//             <div  className='acc-btn'>CHANGE PASSWORD</div>
//             <div  className='acc-btn'>CHANGE PHONE NO.</div>
//             </div>
//             </div>
//           </div>
//         </div>
//         <p style={{ color: '#005F73', textAlign: 'center', fontWeight: '600', fontSize: '20px' }}>Logout</p>
//       </div>
//       <div className='right-container'>
//         <div className='d-flex justify-content-between'>
//           <p>Clinic Details</p>
//           <div>
//             <button className='acc-btn' style={{ marginRight: '7px' }} onClick={toggleSwitchClinicPopup}>SWITCH/ADD CLINIC</button>
//             <button className='acc-btn'  onClick={handleSave} >Save</button>
//           </div>
//         </div>
//         <div>
//           <div className='setup-cont'>
//             <li style={{width:"135px"}}>Clinic Name :</li>
//             <input type='text' className='inp' placeholder=' name '  value={clinicName}
//                 onChange={(e) => setClinicName(e.target.value)} />
//           </div>
//           <div className='setup-cont'>
//             <li style={{width:"135px"}}>Clinic Address :</li>
//             <input type='text' className='inp'  placeholder='' value={clinicAddress}
//                 onChange={(e) => setClinicAddress(e.target.value)}
//                />
//           </div>
//           <div className='setup-cont'>
//             <li style={{width:"135px"}}>Contact No. :</li>
//             <input type='text' className='inp' placeholder='' value={phone}
//                   onChange={(e) => setPhone(e.target.value)}
//                />
//           </div>
//           {/* <div className='setup-cont'>
//             <li style={{width:"135px"}}>Clinic Timing:</li>
//             <input type='text' className='inp'  placeholder='' value={timing}
//                   onChange={(e) => setTiming(e.target.value)}
//                />
//           </div> */}
//         </div>
//       </div>

//       {/* Switch Clinic Popup */}
//       {isSwitchClinicPopupOpen && (
//         <div className='popup-overlay'>
//           <div className='popup-contents' ref={switchClinicPopupRef}>
//             <div className='d-flex justify-content-between'>
//               <h3 style={{color:"#005F73"}}>Switch Clinic</h3>
//               <IoIosCloseCircle onClick={toggleSwitchClinicPopup} style={{ color: "#005F73", width: "25px", height: "30px" }} />
//             </div>


//             <div>
//               {clinics.map((clinic, index) => (
//                 <div className={`cliniclist clinic-item ${currentClinicId === clinic.id ? 'switchclinicA' : ''}`}  key={index}  onClick={() => handleClinicSwitch(clinic.id)}>
//                   <FaClinicMedical style={{ marginBottom: "17px", marginRight: "9px" }} />
//                   <h6 style={{fontSize:"20px"}}>{clinic.clinic_name}</h6>
//                 </div>
//               ))}
//             </div>
//             <div>
//             <button className='acc-btn mb-2'  onClick={() => setIsAddClinicFormVisible(!isAddClinicFormVisible)}>
//               ADD MORE
//             </button>
//             </div>
//             {isAddClinicFormVisible && (
//               <div style={{backgroundColor:"whitesmoke", padding:"1vw", borderRadius:"15px"}}>
//                 <div className='d-flex mb-2 ' >
//                   <li style={{ width: '186px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Name</li>
//                   <input
//                     type='text'
//                     className='inp'
//                     style={{ maxWidth: "400px" }}
//                     value={clinicName}
//                     onChange={(e) => setClinicName(e.target.value)}
//                   />
//                 </div>
//                 <div className='d-flex mb-2'>
//                   <li style={{ width: '190px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Address</li>
//                   <textarea
//                     className='textarea'
//                     value={clinicAddress}
//                     onChange={(e) => setClinicAddress(e.target.value)}
//                   />
//                 </div>
//                 <div className='d-flex mb-3'>
//                   <li style={{ width: '186px', fontWeight: "500", whiteSpace: "nowrap" }}>Contact No.</li>
//                   <input
//                     type='text'
//                     className='inp'
//                     style={{ maxWidth: "400px" }}
//                     value={phone}
//                     onChange={(e) => setPhone(e.target.value)}
//                   />
//                 </div>
//                 <div style={{ display: "flex", justifyContent: "center" }}>
//                   <button className='acc-btn' style={{ width: "80px" }} onClick={handleAddClinic}>
//                     Save
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       )}
//       {/* Edit Details Popup */}
//       {isEditDetailsPopupOpen && (
//         <div className='popup-overlay'>
//           <div className='popup-content' ref={editDetailsPopupRef}>
//             <div className='d-flex justify-content-between'>
//               <h3>Edit Client Details</h3>
//               <IoIosCloseCircle onClick={toggleEditDetailsPopup} style={{ color: "#005F73", width: "25px", height: "30px" }} />
//             </div>
//             <div className='d-flex'>
//               <li style={{ width: '181px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Name</li>
//               <input type='text' className='inp' style={{ maxWidth: "400px" }} />
//             </div>
//             <div className='d-flex'>
//               <li style={{ width: '186px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Address</li>
//               <textarea type='text' className='textarea' />
//             </div>
//             <div className='d-flex'>
//             <li style={{ width: '180px', fontWeight: "500", whiteSpace: "nowrap" }}>Contact No.</li>
//             <input type='number' className='inputs' placeholder='+91' />
//             <input type='number' className='inp' style={{ maxWidth: '356px' }} />
//           </div>
//             <div style={{ display: "flex", justifyContent: "center" }}>
//               <button className='acc-btn ' style={{ width: "80px" }}>Save</button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Account;
import React, { useState, useEffect, useRef,useContext } from 'react';
import { FaClinicMedical } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import './style.css';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import { IoSearchOutline } from "react-icons/io5";
import config from '../../config';

function Account() {
  const [isSwitchClinicPopupOpen, setIsSwitchClinicPopupOpen] = useState(false);
  const [isEditDetailsPopupOpen, setIsEditDetailsPopupOpen] = useState(false);
  const switchClinicPopupRef = useRef(null);
  const editDetailsPopupRef = useRef(null);
  const { authTokens } = useContext(AuthContext);
  const [activeClinic, setActiveClinic] = useState(null);

  // Function to toggle the Switch Clinic popup
  const toggleSwitchClinicPopup = () => {
    setIsSwitchClinicPopupOpen(!isSwitchClinicPopupOpen);
    setIsEditDetailsPopupOpen(false); // Close the other popup if open
    setIsAddClinicFormVisible(false); // Hide the add form initially
    if (!isSwitchClinicPopupOpen) fetchClinics(); 
  };

  // Function to toggle the Edit Details popup
  const toggleEditDetailsPopup = () => {
    setIsEditDetailsPopupOpen(!isEditDetailsPopupOpen);
    setIsSwitchClinicPopupOpen(false); // Close the other popup if open
  };

  useEffect(() => {
    if (!isSwitchClinicPopupOpen) fetchClinics();
  }, [isSwitchClinicPopupOpen]);
  // Close the popups when clicking outside of them
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isSwitchClinicPopupOpen && switchClinicPopupRef.current && !switchClinicPopupRef.current.contains(event.target)) {
        setIsSwitchClinicPopupOpen(false);
      }
      if (isEditDetailsPopupOpen && editDetailsPopupRef.current && !editDetailsPopupRef.current.contains(event.target)) {
        setIsEditDetailsPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSwitchClinicPopupOpen, isEditDetailsPopupOpen]);


  const [isAddClinicFormVisible, setIsAddClinicFormVisible] = useState(false);
  const [clinicName, setClinicName] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [timing, setTiming] = useState("");
  const [clinics, setClinics] = useState([]);

  const [isRequestClinicVisible, setIsRequestClinicVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [allClinics, setAllClinics] = useState([]);
  const [filteredClinics, setFilteredClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);


  const fetchClinics = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/api/v1/clinic-management/client-clinic-profile/`,{
        headers: {
          Authorization: `JWT ${authTokens?.access}`,
        },
      }); // Replace with your API endpoint
    
      setClinics(response.data);
    } catch (error) {
      console.error('Error fetching clinics:', error);
    }
  };

  const fetchClinicslist = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/api/v1/clinic-management/clinic-profile/`, {
        headers: {
          Authorization: `JWT ${authTokens?.access}`,
        },
      });
      // setClinics(response.data);
      setAllClinics(response.data);
    } catch (error) {
      console.error('Error fetching clinics:', error);
    }
  };

  const handleClinicSwitch = async (clinicId) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/api/v1/user/change-clinic/`, 
        { clinic_id: clinicId }, 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${authTokens?.access}`,
          },
        });
      setActiveClinic(response.data); // Update the active clinic based on response
      localStorage.setItem('user', JSON.stringify(response.data));
      alert('Clinic switched successfully!');
      setIsSwitchClinicPopupOpen(false);
    } catch (error) {
      console.error('Error switching clinic:', error);
    }
  };
  console.log(clinics)
  const handleAddClinic = async () => {
    try {
      const newClinic = { clinic_name: clinicName,  clinic_address: clinicAddress,  contact: phone ,is_doctor_admin:true};
      await axios.post(`${config.API_BASE_URL}/api/v1/clinic-management/clinic-profile/`, newClinic,
        {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${authTokens?.access}`,
        },
      }
      ); // Replace with your API endpoint
      alert('Clinic added successfully!');
      fetchClinics();
      setClinicName("");
      setClinicAddress("");
      setPhone("");
      setIsAddClinicFormVisible(false); // Hide the form after adding
      
    } catch (error) {
      console.error('Error adding clinic:', error);
    }
  };
  const handleSave = () => {
   
  };
  const userData = JSON.parse(localStorage.getItem('user'));
  const currentClinicId = userData?.current_clinic;
  const doctorid = userData?.id;

  // Handle search input change
  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    
    if (term.length > 0) {
      const filtered = allClinics.filter(clinic => 
        clinic.clinic_name.toLowerCase().includes(term)
      );
      setFilteredClinics(filtered);
      setIsDropdownOpen(true);
    } else {
      setFilteredClinics([]);
      setIsDropdownOpen(false);
    }
  };

  // Handle clinic selection
  const handleClinicSelect = (clinic) => {
    setSelectedClinic(clinic);
    setSearchTerm(clinic.clinic_name);
    setIsDropdownOpen(false);
  };

  // Handle request clinic submission
  const handleRequestClinic = async () => {
    if (!selectedClinic) return;
    
    try {
      await axios.post(`${config.API_BASE_URL}/api/v1/clinic-management/doctors-send-request/`, 
        { clinic_id: selectedClinic.id,
          doctor_id:doctorid ,
         },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${authTokens?.access}`,
          },
        }
      );
      alert('Clinic request sent successfully!');
      setIsRequestClinicVisible(false);
      setSelectedClinic(null);
      setSearchTerm('');
      fetchClinicslist(); // Refresh the clinic list
    } catch (error) {
      console.error('Error requesting clinic:', error);
      alert('Failed to send clinic request');
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchClinicslist();
  }, []);



  const user = JSON.parse(localStorage.getItem('user'));
  const isDoctorOwner = user?.roles.some(role => role.role_name === 'IS_DOCTOR_OWNER');
  const isadmin = user?.roles.some(role => role.role_name === 'IS_ADMIN');


  return (
    <div className='account-container'>
      <div className='right-container'>
        <h3>Account Setup</h3>
        <p>User Information</p>
        <div className='setup-cont'>
          <img src='/user.png' alt='user' style={{ height: '140px', width: '130px', marginRight: '20px' }} />
          <div>
            <div className='d-flex'>
            <div>
          <li className='list my-3'>Email Id</li>
          <li className='list my-3'>Password</li>
          <li className='list my-3'>Phone No.</li>
          </div>
            <div className='input-fields'>
            <input type='email' placeholder='email' className='inp' />
            
            <input type='number' placeholder='Number' className='inp' />
            
            <input type='number' placeholder='Number' className='inp' />
            

            </div>
            <div className='input-fields' style={{marginLeft:"5%"}}>
            <div className='acc-btn'>CHANGE ACCOUNT</div>
            <div  className='acc-btn'>CHANGE PASSWORD</div>
            <div  className='acc-btn'>CHANGE PHONE NO.</div>
            </div>
            </div>
          </div>
        </div>
        <p style={{ color: '#005F73', textAlign: 'center', fontWeight: '600', fontSize: '20px' }}>Logout</p>
      </div>
      <div className='right-container'>
        <div className='d-flex justify-content-between'>
          <p>Clinic Details</p>
          <div>
            <button className='acc-btn' style={{ marginRight: '7px' }} onClick={toggleSwitchClinicPopup}>SWITCH/ADD CLINIC</button>
            <button className='acc-btn'  onClick={handleSave} >Save</button>
          </div>
        </div>
        <div>
          <div className='setup-cont'>
            <li style={{width:"135px"}}>Clinic Name :</li>
            <input type='text' className='inp' placeholder=' name '  value={clinicName}
                onChange={(e) => setClinicName(e.target.value)} />
          </div>
          <div className='setup-cont'>
            <li style={{width:"135px"}}>Clinic Address :</li>
            <input type='text' className='inp'  placeholder='' value={clinicAddress}
                onChange={(e) => setClinicAddress(e.target.value)}
               />
          </div>
          <div className='setup-cont'>
            <li style={{width:"135px"}}>Contact No. :</li>
            <input type='text' className='inp' placeholder='' value={phone}
                  onChange={(e) => setPhone(e.target.value)}
               />
          </div>
          {/* <div className='setup-cont'>
            <li style={{width:"135px"}}>Clinic Timing:</li>
            <input type='text' className='inp'  placeholder='' value={timing}
                  onChange={(e) => setTiming(e.target.value)}
               />
          </div> */}
        </div>
      </div>

      {/* Switch Clinic Popup */}
      {isSwitchClinicPopupOpen && (
        <div className='popup-overlay'>
          <div className='popup-contents' ref={switchClinicPopupRef}>
            <div className='d-flex justify-content-between'>
              <h3 style={{color:"#005F73"}}>Switch Clinic</h3>
              <IoIosCloseCircle onClick={toggleSwitchClinicPopup} style={{ color: "#005F73", width: "25px", height: "30px" }} />
            </div>


            <div>
              {clinics.map((clinic, index) => (
                <div className={`cliniclist clinic-item ${currentClinicId === clinic.id ? 'switchclinicA' : ''}`}  key={index}  onClick={() => handleClinicSwitch(clinic.id)}>
                  <FaClinicMedical style={{ marginBottom: "17px", marginRight: "9px" }} />
                  <h6 style={{fontSize:"20px"}}>{clinic.clinic_name}</h6>
                </div>
              ))}
            </div>
            <div>
            <button className='acc-btn mb-2'  onClick={() => setIsAddClinicFormVisible(!isAddClinicFormVisible)}>
              ADD MORE
            </button>
            {(!isDoctorOwner && !isadmin)  && (
              <button className='acc-btn' onClick={() => setIsRequestClinicVisible(!isRequestClinicVisible)}>
                REQUEST CLINIC
              </button>
            )}
            
            </div>
            {isRequestClinicVisible && (
              <div style={{backgroundColor:"whitesmoke", padding:"1vw", borderRadius:"15px"}} className="mb-3">
                <div className="search-container position-relative" ref={dropdownRef}>
                  <div className="d-flex align-items-center mb-2">
                    <input
                      type="text"
                      className="inp flex-grow-1"
                      placeholder="Search clinic..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <IoSearchOutline className="search-icon" />
                  </div>
                  
                  {isDropdownOpen && filteredClinics.length > 0 && (
                    <div className="search-dropdown">
                      {filteredClinics.map((clinic) => (
                        <div
                          key={clinic.id}
                          className="search-item"
                          onClick={() => handleClinicSelect(clinic)}
                        >
                          {clinic.clinic_name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                
                {selectedClinic && (
                  <div className="selected-clinic-info mb-2">
                    <p><strong>Selected Clinic:</strong> {selectedClinic.clinic_name}</p>
                    <p>{selectedClinic.clinic_address}</p>
                  </div>
                )}

                <div className="d-flex justify-content-center">
                  <button 
                    className='acc-btn'
                    onClick={handleRequestClinic}
                    disabled={!selectedClinic}
                  >
                    Submit Request
                  </button>
                </div>
              </div>
            )}
            {isAddClinicFormVisible && (
              <div style={{backgroundColor:"whitesmoke", padding:"1vw", borderRadius:"15px"}}>
                <div className='d-flex mb-2 ' >
                  <li style={{ width: '186px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Name</li>
                  <input
                    type='text'
                    className='inp'
                    style={{ maxWidth: "400px" }}
                    value={clinicName}
                    onChange={(e) => setClinicName(e.target.value)}
                  />
                </div>
                <div className='d-flex mb-2'>
                  <li style={{ width: '190px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Address</li>
                  <textarea
                    className='textarea'
                    value={clinicAddress}
                    onChange={(e) => setClinicAddress(e.target.value)}
                  />
                </div>
                <div className='d-flex mb-3'>
                  <li style={{ width: '186px', fontWeight: "500", whiteSpace: "nowrap" }}>Contact No.</li>
                  <input
                    type='text'
                    className='inp'
                    style={{ maxWidth: "400px" }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button className='acc-btn' style={{ width: "80px" }} onClick={handleAddClinic}>
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* Edit Details Popup */}
      {isEditDetailsPopupOpen && (
        <div className='popup-overlay'>
          <div className='popup-content' ref={editDetailsPopupRef}>
            <div className='d-flex justify-content-between'>
              <h3>Edit Client Details</h3>
              <IoIosCloseCircle onClick={toggleEditDetailsPopup} style={{ color: "#005F73", width: "25px", height: "30px" }} />
            </div>
            <div className='d-flex'>
              <li style={{ width: '181px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Name</li>
              <input type='text' className='inp' style={{ maxWidth: "400px" }} />
            </div>
            <div className='d-flex'>
              <li style={{ width: '186px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Address</li>
              <textarea type='text' className='textarea' />
            </div>
            <div className='d-flex'>
            <li style={{ width: '180px', fontWeight: "500", whiteSpace: "nowrap" }}>Contact No.</li>
            <input type='number' className='inputs' placeholder='+91' />
            <input type='number' className='inp' style={{ maxWidth: '356px' }} />
          </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className='acc-btn ' style={{ width: "80px" }}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Account;
