import React, { useState, useEffect, useContext,useRef } from 'react';
import PopupForm from './PopupForm';
import AuthContext from '../../context/AuthContext';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import NavBar from '../nav_sidebar/NavBar';
import SideBar from '../nav_sidebar/SideBar';
import useAxios from '../../utils/useAxios';

function ReceptionistPage() {
  const [userName, setUserName] = useState('');
 const [user, setUser] = useState([]);
 const axiosInstance = useAxios();
 const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
 const sidebarRef = useRef(null);
  const [receptionists, setReceptionists] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [selectedClinicId, setSelectedClinicId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState(null);
  const navigate = useNavigate();
  const { authTokens } = useContext(AuthContext);


  const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle);
   };
  
   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axiosInstance.get('auth/users/me/');
          setUser(response.data);
          setUserName(response.data.full_name);
        } catch (error) {
          console.error('Failed to fetch data', error);
        }
      };
  
      fetchData();
  
      const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setOpenSidebarToggle(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
   }, [axiosInstance]);
  
  useEffect(() => {
    const fetchClinics = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/api/v1/clinic-management/client-clinic-profile/`,
          {
            headers: {
              'Authorization': `JWT ${authTokens?.access}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setClinics(response.data);
        // Set the first clinic as selected by default
        if (response.data.length > 0) {
          setSelectedClinicId(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching clinics:', error);
      }
    };

    if (authTokens?.access) {
      fetchClinics();
    }
  }, [authTokens]);

 

  const fetchReceptionists = async (clinicId) => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/api/v1/clinic-management/receptionist-clinic/?clinic_id=${clinicId}`,
        {
          headers: {
            Authorization: `JWT ${authTokens?.access}`,
          },
        }
      );

      
      console.log("API Response:", response.data);
      const activeReceptionists = response.data.filter(
        receptionist => receptionist.is_active == true 
      );
      console.log("hgh",activeReceptionists);
      setReceptionists(activeReceptionists);
    } catch (error) {
      console.error('Error fetching receptionists:', error);
    }
  };

  useEffect(() => {
    if (selectedClinicId) {
      fetchReceptionists(selectedClinicId);
    }
  }, [selectedClinicId, authTokens]);

  const handleAddOrEdit = async (receptionistData) => {
    try {
      if (editData) {
        // Handle edit functionality if needed
      
        await axios.put(
          `${config.API_BASE_URL}/api/v1/clinic-management/receptionist-clinic/${editData.id}/`,
          {
            clinic_id: selectedClinicId,
            first_name: receptionistData['First Name'],
            last_name: receptionistData['Last Name'],
            phone_number: receptionistData['Phone Number'],
            email: receptionistData['Email'],
            password: receptionistData.password,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${authTokens?.access}`,
            },
          }
        );
        // await fetchReceptionists(selectedClinicId);
      } else {
        const response = await axios.post(
          `${config.API_BASE_URL}/api/v1/clinic-management/add-receptionist-clinic/`,
          {
            clinic_id: selectedClinicId,
            first_name: receptionistData['First Name'],
            last_name: receptionistData['Last Name'],
            phone_number: receptionistData['Phone Number'],
            email: receptionistData['Email'],
            password: receptionistData.password,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${authTokens?.access}`,
            },
          }
        );

        if (response.status === 201 || response.status === 200) {
          fetchReceptionists(selectedClinicId);
        }
      }
      setShowPopup(false);
      setEditIndex(null);
    } catch (error) {
      console.error('Error adding/editing receptionist:', error);
      alert('Error adding/editing receptionist. Please try again.');
    }
  };

  const handleEdit = async (id) => {
    try {
      // Fetch the specific receptionist details including password
      const response = await axios.get(
        `${config.API_BASE_URL}/api/v1/clinic-management/receptionist-clinic/${id}/`,
        {
          headers: {
            Authorization: `JWT ${authTokens?.access}`,
          },
        }
      );
      
      const receptionistToEdit = response.data;
      
      if (receptionistToEdit) {
        // Format the data to match the form fields, now including password
        const formattedData = {
          'First Name': receptionistToEdit.receptionist.first_name,
          'Last Name': receptionistToEdit.receptionist.last_name,
          'Phone Number': receptionistToEdit.receptionist.phone_number,
          'Email': receptionistToEdit.receptionist.email,
          'password': receptionistToEdit.receptionist.password, // Include password
          'clinic_id': receptionistToEdit.id
        };

        setEditData({
          id: id,
          formData: formattedData
        });
        setSelectedClinicId(receptionistToEdit.clinic.id); // Set the clinic ID
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error fetching receptionist details:', error);
      alert('Error fetching receptionist details. Please try again.');
    }
  };
  

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredReceptionists = receptionists.filter((receptionist) => {
    const fullName = `${receptionist.receptionist.first_name} ${receptionist.receptionist.last_name}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });


  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${config.API_BASE_URL}/api/v1/clinic-management/receptionist-clinic/${id}/`,
        {
          headers: {
            Authorization: `JWT ${authTokens?.access}`,
          },
        }
      );
      fetchReceptionists(selectedClinicId); // Refresh the receptionist list
    } catch (error) {
      console.error('Error deleting receptionist:', error);
      alert('Error deleting receptionist. Please try again.');
    }
  };
  return (
    <div>
      <NavBar 
        OpenSidebar={OpenSidebar} 
        openSidebarToggle={openSidebarToggle} 
        userName={userName} 
      />
      <SideBar 
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle} 
        OpenSidebar={OpenSidebar} 
      />
    <div className="px-5 mt-4">
      <div className="d-flex justify-content-between">
        <p className="headings">List of Receptionists</p>
        <button className="edit-btn" onClick={() => navigate("/admin")}>
          Back
        </button>
      </div>

      <div className="d-flex mb-3">
       
        <input
          type="text"
          className="searchbar mt-2"
          placeholder="Search by Name"
          value={searchQuery}
          onChange={handleSearchChange}
        />
         <select
          value={selectedClinicId}
          onChange={(e) => setSelectedClinicId(e.target.value)}
          className="searchbar"
        >
          {clinics.map((clinic) => (
            <option key={clinic.id} value={clinic.id}>
              {clinic.clinic_name}
            </option>
          ))}
        </select>
        <button className="addbtn" onClick={() => setShowPopup(true)}>
          Add Receptionist
        </button>
      </div>

      {showPopup && (
        <PopupForm
          fields={['First Name', 'Last Name', 'Phone Number', 'Email']}
          initialValues={editData !== null ? editData.formData : {}}
          onSubmit={handleAddOrEdit}
          onClose={() => {
            setShowPopup(false);
            setEditIndex(null);
          }}
          popupname="Receptionist"
        />
      )}

      <table className="table table-striped table-hover table-bordered">
        <thead className="table-header">
          <tr>
            <th>Sl. No.</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        {filteredReceptionists.length > 0 ? (
          <tbody>
            {filteredReceptionists.map((r, index) => (
              <tr key={r.id || index}>
                <td>{index + 1}</td>
                <td>{r.receptionist.first_name} {r.receptionist.last_name}</td>
                <td>{r.receptionist.phone_number}</td>
                <td>{r.receptionist.email}</td>
                <td>
                  <button className="btn edit-btn" onClick={() => handleEdit(r.id)}>Edit</button>
                  <button className="btn delete-btn" onClick={() => handleDelete(r.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="5" className="text-center">
                No Receptionist available.
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
    </div>
  );
}

export default ReceptionistPage;
