




import React, { useState, useMemo, useEffect ,useContext} from 'react';
import Searchbar from './Searchbar';
import './style.css';
import { GiMedicines } from "react-icons/gi";
import { MdOutlineCancel } from "react-icons/md";
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import config from '../../config';

function MedicineTemplets({ onBack }) {
  const { authTokens } = useContext(AuthContext);
  const [isFullPageView, setIsFullPageView] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editingTemplet, setEditingTemplet] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [templetsData, setTempletsData] = useState([]);
  

  const doctorData = JSON.parse(localStorage.getItem('user'));
  const doctor_id = doctorData?.id;
  useEffect(() => {
    fetchTemplates();
  }, []);
  const fetchTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${config.API_BASE_URL}/api/v1/template-creator/medicine/${doctor_id}/`, {
        headers: { 'Authorization': `JWT ${authTokens?.access}` },
      });
      console.log("meditemp",response.data)
      
      // Transform the response to match your existing component structure
      // const transformedTemplates = [{
      //   // id: response.data.medicines.medicine_id, 
      
      //   dosageDetails: response.data.medicines.map(medicine => ({
      //     id:medicine.medicine_template_id,
      //     templatename:medicine.medicine_template_name,
      //     name: medicine.medicine_name,
      //     dosage:medicine.medicine_dosage,
      //     timing:medicine.medicine_timings,
      //     modality:medicine.medicine_modality,
      //     duration: medicine.medicine_duration
      //   }))
      // }];
   
      setTempletsData(response.data.medicines);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to fetch medicine templates');
      setIsLoading(false);
      console.error('Error fetching templates:', err);
    }
  };

  console.log("templa",templetsData)

  const openFullPageView = () => setIsFullPageView(true);
  const closeFullPageView = () => {
    setIsFullPageView(false);
    onBack();
  };

  const openPopup = (templet ) => {
    setEditingTemplet(templet);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setEditingTemplet(null);
  };

  
  const saveTemplet = async (savedTemplet) => {
    console.log("savedTemplet",savedTemplet)
    try {

    
        // Prepare the data according to the backend's expected format
        const formattedTemplet = {
            doctor_id: doctor_id, // Add the doctor_id from session storage
            medicine_template_name: savedTemplet.name,
            medicines: savedTemplet.dosageDetails.map(medicine => ({
                medicine_name: medicine.value,
                medicine_dosage: medicine.dosage.split(" ")[0], // Assuming dosage is like "500mg"
                medicine_timings: medicine.timing || '', // You might want to handle this based on your UI
                medicine_modality: medicine.modality || '', // Handle modality here
                medicine_duration: medicine.duration || '', // Handle duration here
            })),
        };
       console.log("formattedTemplet",formattedTemplet)
        if (editingTemplet) {
            // Update existing template
            const response = await axios.put(`${config.API_BASE_URL}/api/v1/template-creator/medicine/${doctor_id}/${editingTemplet}`, formattedTemplet,
              {
                headers: { 'Authorization': `JWT ${authTokens?.access}` }}
            );
            setTempletsData(templetsData.map(t => 
                t.id === editingTemplet.id ? response.data : t
            ));
        } else {
            // Create new template
            const response = await axios.post(`${config.API_BASE_URL}/api/v1/template-creator/medicine/`, formattedTemplet, {
                headers: { 'Authorization': `JWT ${authTokens?.access}` },
            });
            setTempletsData([...templetsData, response.data]);
            await fetchTemplates();
        }
        closePopup();
    } catch (err) {
        console.error('Error saving template:', err);
        alert('Failed to save template. Please try again.');
    }
};

  const deleteTemplate = async (templateId) => {
    try {
      await axios.delete(`${config.API_BASE_URL}/api/v1/template-creator/medicine/${doctor_id}/${templateId}`,
        {
          headers: { 'Authorization': `JWT ${authTokens?.access}` },
        }
      );

      setTempletsData(templetsData.filter(t => t.id !== templateId));

      await fetchTemplates();
    } catch (err) {
      console.error('Error deleting template:', err);
      alert('Failed to delete template. Please try again.');
    }
  };

  // Render loading state
  if (isLoading) {
    return <div>Loading templates...</div>;
  }

  // Render error state
  if (error) {
    return <div>{error}</div>;
  }


  return isFullPageView ? (
    <div className='full-page-view'>
      <div className='d-flex justify-content-between'>
        <h4 style={{ color: '#0A9396' }}>Medicine Templates</h4>
        <button onClick={closeFullPageView} className='acc-btn' style={{padding:"5px 17px"}}>
          Back
        </button>
      </div>
      <label style={{marginLeft:"5px",fontWeight:"500"}}>Search Template</label>
      <div>
        <input
          type='text'
          placeholder='Search Template...'
          className='search-input'
          
        />
        <button className='acc-btn' onClick={() => openPopup()}>Add More</button>
      </div>
      
      <table className='medicine'>
        <thead>
          <tr>
            <th>Sl.No.</th>
            <th>Template Name</th>
            <th>Medicine Name</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {(templetsData || []).map((templet, index) => (
            <React.Fragment key={templet.medicine_id}>
              <tr>
                <td>{index + 1}.</td>
                <td>{templet.medicine_template_name}</td>
                <td>{templet.
medicine_name}</td>
                <td><button className='edit-btn' onClick={() => openPopup(templet.medicine_id)}>Edit</button></td>
                <td><button className='delete-btn' onClick={() => deleteTemplate(templet.medicine_id)}>Delete</button></td>
              </tr>
              <tr>
                <td colSpan="5">
                  <div className="dosage-details">
                    <h5>Dosage Details</h5>
                    {/* {(templet.dosageDetails || []).map((dosage, idx) => (
                      <div key={idx}>
                        <div className='d-flex'>
                          <p style={{marginBottom:"44px",marginRight:"10px",color:"rgba(128, 128, 128, 0.718)",fontWeight:"bold"}}>
                            {romanize(idx + 1)}.
                          </p>
                          <div>
                            <li>Medicine Name : {dosage.
medicine_name}</li>
                            <li>Dosage       : {dosage.dosage}</li>
                            <li>Duration     : {dosage.duration}</li>
                          </div>
                        </div>
                      </div>
                    ))} */}
                    
                        <div className='d-flex'>
                          <p style={{marginBottom:"44px",marginRight:"10px",color:"rgba(128, 128, 128, 0.718)",fontWeight:"bold"}}>
                           
                          </p>
                          <div>
                            <li>Medicine Name : {templet.
medicine_name || ""}</li>
                            <li>Dosage       : {templet.medicine_dosage || ""},{
templet.medicine_modality || ""}, {templet.medicine_timings || ""}ml</li>
                            <li>Duration     : {templet.
medicine_duration
|| ""}</li>
                          </div>
                        </div>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {isPopupOpen && (
        <TempletPopup
          templet={editingTemplet}
          onClose={closePopup}
          onSave={saveTemplet}
        />
      )}
    </div>
  ) : (
    <div className='medicine-templates mt-3'>
      <div className='d-flex justify-content-between'>
        <h4 style={{ color: '#0A9396' }}>Medicine Templates</h4>
        <button onClick={openFullPageView} className='acc-btn'>
          View All
        </button>
      </div>
      
      <table>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Template Name</th>
            <th>Medicine Combinations</th>
          </tr>
        </thead>
        <tbody>
          {(templetsData || []).map((templet, index) => (
            <tr key={templet.id}>
             <td>{index + 1}.</td>
                <td>{templet.medicine_template_name}</td>
                <td>{templet.
medicine_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function TempletPopup({ templetsData, onClose, onSave }) {
  const { authTokens } = useContext(AuthContext);
  const doctorData = JSON.parse(localStorage.getItem('user'));
  const doctor_id = doctorData?.id;
  const [currentTemplet, setCurrentTemplet] = useState({
    name: templetsData?.medicine_template_name || "",
    dosage: templetsData?.medicine_dosage || "",
    timing: templetsData?.medicine_timings || "",
    modality: templetsData?.medicine_modality || "",
    duration: templetsData?.medicine_duration || "",
    medicine_id: templetsData?.medicine_id || "",
});
  // const [selectedMedicines, setSelectedMedicines] = useState(templetsData ? templetsData.map(med => ({
  //   ...med,
  //   // Ensure all possible fields are initialized
  //   timing: med.timing || '',
  //   dosage: med.dosage || '',
  //   modality: med.modality || '',
  //   duration: med.duration || '',
  //   unit: med.unit || '',
  //   dosageUnit: med.dosageUnit || ''
  // })) : []);
  const [selectedMedicines, setSelectedMedicines] = useState(
    templetsData ? [{
        medicine_id: templetsData.medicine_id,
        medicine_name: templetsData.medicine_name,
        dosage: templetsData.medicine_dosage,
        timing: templetsData.medicine_timings,
        modality: templetsData.medicine_modality,
        duration: templetsData.medicine_duration,
        // unit:templetsData.
        // Add any other fields you need here
    }] : []
  );
  const [activeMedicine, setActiveMedicine] = useState(null);
  const [medicines, setMedicines] = useState([]);

  const [selectedTimings, setSelectedTimings] = useState(selectedMedicines.map(med => med.timing || []));
  const [selectedDosages, setSelectedDosages] = useState(selectedMedicines.map(med => med.dosage || ''));
  const [selectedDosageUnits, setSelectedDosageUnits] = useState(selectedMedicines.map(med => med.dosageUnit || ''));
  const [selectedModalities, setSelectedModalities] = useState(selectedMedicines.map(med => med.modality || ''));
  const [selectedDurations, setSelectedDurations] = useState(selectedMedicines.map(med => med.duration || ''));


  const timings = useMemo(() => [
    { label: '5 ml', value: '5' },
    { label: '10 ml', value: '10' },
    { label: '15 ml', value: '15' },
  ], []);

  const dosage = useMemo(() => [
    { label: 'once', value: 'once' },
    { label: '2 times', value: '2times' },
    { label: '3 times', value: '3times' },
  ], []);

  const modality = useMemo(() => [
    { label: 'Before Food', value: 'BF' },
    { label: 'After Food', value: 'AF' },
    { label: 'With Food', value: 'WF' },
  ], []);

  const duration = useMemo(() => [
    { label: '1 d', value: '1' },
    { label: '2 d', value: '2' },
    { label: '3 d', value: '3' },
    { label: '4 d', value: '4' },
    { label: '5 d', value: '5' },
    { label: '7 d', value: '7' },
  ], []);

  const unit = useMemo(() => [
    { label: '5 ml', value: '5' },
    { label: '10 ml', value: '10' },
    { label: '15 ml', value: '15' },
  ], []);

  // useEffect(() => {
  //   axios.get(`http://localhost:8000/api/v1/template-creator/medicine/${doctor_id}/${editingTemplet}`)
  //     .then(response => {
  //       setMedicines(response.data);
  //     })
  //     .catch(error => {
  //       console.error('There was an error fetching the medicines!', error);
  //     });
  // }, []);

  const handleSearchDropdownChange = (values) => {
    const newMedicines = values.map(value => {
        const medicineData = medicines.find(m => m.id === value.value);
        if (!medicineData) {
            console.error(`Medicine with id ${value.value} not found.`);
            return { ...value, timing: '', dosage: '', modality: '', duration: '', unit: '' };
        }
        return {
            ...value,
            timing: medicineData.medicine_timings || '',
            dosage: medicineData.medicine_dosage || '',
            modality: medicineData.medicine_modality || '',
            duration: medicineData.medicine_duration || '',
            unit: medicineData.unit || '', // Ensure this field exists in your medicine data
        };
    });

    setSelectedMedicines(prevMedicines => {
        const updatedMedicines = [...prevMedicines, ...newMedicines];
        if (updatedMedicines.length > 0) {
            setActiveMedicine(updatedMedicines[updatedMedicines.length - 1]);
        }
        return updatedMedicines;
    });
};

  const handleCancelClick = (index) => {
    setSelectedMedicines(prevMedicines => {
      const newMedicines = prevMedicines.filter((_, i) => i !== index);
      if (activeMedicine && prevMedicines[index] === activeMedicine) {
        setActiveMedicine(newMedicines.length > 0 ? newMedicines[0] : null);
      }
      return newMedicines;
    });
  };

  const handleInputChange = (field, value) => {
    if (activeMedicine) {
      // Update the active medicine directly
      const updatedMedicine = {
        ...activeMedicine,
        [field]: value
      };

      // Update the medicines array
      setSelectedMedicines(prevMedicines => 
        prevMedicines.map(med => 
          med === activeMedicine ? updatedMedicine : med
        )
      );

      // Update the active medicine state
      setActiveMedicine(updatedMedicine);
    }
  };


  const handleButtonClick = (field, value) => {
    if (activeMedicine) {
      // Determine the new value (toggle if already selected)
      const newValue = activeMedicine[field] === value ? '' : value;

      // Create an updated medicine object
      const updatedMedicine = {
        ...activeMedicine,
        [field]: newValue
      };

      // Update the medicines array
      setSelectedMedicines(prevMedicines => 
        prevMedicines.map(med => 
          med === activeMedicine ? updatedMedicine : med
        )
      );

      // Update the active medicine state
      setActiveMedicine(updatedMedicine);
    }
  };




  const handleSave = () => {
    const updatedTemplet = {
      ...currentTemplet,
      dosageDetails: selectedMedicines ,
      combinations: selectedMedicines.map(med => med.label).join(", ")
    };
    onSave(updatedTemplet);
    console.log("kknlk",updatedTemplet)
  };



  

  return (
    <div className="edit-popup">
      <div className="edit-popup-content">
        <div className="d-flex justify-content-between">
          <div> </div>
          <div>
            <button onClick={handleSave} className='acc-btn mx-1'>Save & Close</button>
            <button onClick={onClose} className='acc-btn'>Back</button>
          </div>
        </div>
        <div className='d-flex'>
          <div>
            <p style={{whiteSpace:"nowrap",fontWeight:"600"}}> Template Name </p>
            <p style={{whiteSpace:"nowrap",fontWeight:"600"}} className='mt-4'> Search/Add Medicine</p>
          </div>
          <div>
            <input 
              type='text' 
              placeholder='Template name' 
              className='temp-inp py-1'
              value={currentTemplet.name}
              onChange={(e) => setCurrentTemplet({...currentTemplet, name: e.target.value})}
              style={{height:"40px"}}
            />
            <Searchbar 
              options={medicines} 
              placeholder={'Search or add Medicines'} 
              onValuesChange={handleSearchDropdownChange} 
              style={{border:"2px solid"}}
              // Add a prop to allow multiple selections
              isMulti={true}
            />
          </div>
        </div>

        <div className="medicine-container">
          <div className="medlist">
            {selectedMedicines.map((medicine, index) => (
              <div 
                key={index} 
                className={activeMedicine === medicine ? 'meds selected' : 'meds'} 
                onClick={() => setActiveMedicine(medicine)}
              >
                <GiMedicines className="icon" style={{fontSize: "2vw"}} />
                {medicine.label}
                <div onClick={() => handleCancelClick(index)}>
                  <MdOutlineCancel />
                </div>
              </div>
            ))}
          </div>

          {activeMedicine ? (
            <div className="medtimings">
              <div className='timebox'>
                <h6 style={{color: "#005F73", fontSize: '1vw'}}>{activeMedicine.label}</h6>
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start", width:"100%", height:"90%", padding:"0 0.5vw", gap:'1vh'}}>
                <h5 style={{fontSize: '1.4vw', margin: '0'}}>Dosage</h5>
                  {/* <div className="button-container" style={{height: '30%', justifyContent: 'flex-start', alignItems: 'center', fontSize: '1.3vw'}}>
                    {unit.map((dtext, dindex) => (
                      <button
                        key={dindex}
                        className={activeMedicine.unit === dtext.value ? 'btnselected' : 'btnunselected'}
                        onClick={() => handleButtonClick('unit', dtext.value)}
                      >
                        {dtext.label}
                      </button>
                    ))}
                
                    <input 
                      type="number" 
                      value={activeMedicine.unit || ''} 
                      onChange={(e) => handleInputChange('unit', e.target.value)} 
                    />
                    <h5>ml</h5>
                  </div> */}
                  <div className="button-container" style={{width: '100%', height: '30%', justifyContent: 'flex-start', alignItems: 'center', fontSize: '1.3vw'}}>
                    {timings.map((ttext, tindex) => (
                      <button
                        key={tindex}
                        className={activeMedicine.timing === ttext.value ? 'btnselected' : 'btnunselected'}
                        onClick={() => handleButtonClick('timing', ttext.value)}
                      >
                        {ttext.label}
                      </button>
                    ))}
                    <input 
                      type="number" 
                      value={activeMedicine.timing || ''} 
                      onChange={(e) => handleInputChange('timing', e.target.value)} 
                    />
                    <h5>ml</h5>
                  </div>
                  <div className="button-container" style={{height: '30%', justifyContent: 'flex-start', alignItems: 'center', fontSize: '1.3vw'}}>
                    {dosage.map((dtext, dindex) => (
                      <button 
                        key={dindex}
                        style={{width: '15%'}} 
                        className={activeMedicine.dosage === dtext.value ? 'btnselected' : 'btnunselected'}  
                        onClick={() => handleButtonClick('dosage', dtext.value)}
                      >
                        {dtext.label}
                      </button>
                    ))}
                    <input 
                      type="text" 
                      value={activeMedicine.dosage || ''} 
                      onChange={(e) => handleInputChange('dosage', e.target.value)}
                    />
                    <select 
                      value={activeMedicine.dosageUnit || ''}
                      onChange={(e) => handleInputChange('dosageUnit', e.target.value)}
                    >
                      <option value="Per Day">Per Day</option>
                      <option value="Per month">Per month</option>
                    </select>
                  </div>
                  <div className="button-container" style={{height: '30%', justifyContent: 'flex-start', alignItems: 'center', fontSize: '1.3vw'}}>
                    {modality.map((mtext, mindex) => (
                      <button 
                        key={mindex}
                        className={activeMedicine.modality === mtext.value ? 'btnselected' : 'btnunselected'}
                        onClick={() => handleButtonClick('modality', mtext.value)}
                      >
                        {mtext.label}
                      </button>
                    ))}
                  </div>
                  <div className="button-container" style={{height: '30%', justifyContent: 'flex-start', alignItems: 'center', fontSize: '1.3vw'}}>
                    {duration.map((dtext, dindex) => (
                      <button
                        key={dindex}
                        className={activeMedicine.duration === dtext.value ? 'btnselected' : 'btnunselected'}
                        onClick={() => handleButtonClick('duration', dtext.value)}
                      >
                        {dtext.label}
                      </button>
                    ))}
                
                    <input 
                      type="number" 
                      value={activeMedicine.duration || ''} 
                      onChange={(e) => handleInputChange('duration', e.target.value)} 
                    />
                    <h5>Days</h5>
                  </div>
                </div>
              </div>
            </div>
          ):(
            <div style={{display:"flex",justifyContent:"center", margin:"4vw", color:"grey"}}>
              <h5>Please search or manually enter a medicine name to add</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function romanize(num) {
  const roman = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
  return roman[num - 1] || num;
}

export default MedicineTemplets;