import React, { useState, useEffect,useContext ,useRef} from 'react';
import PopupForm from './PopupForm';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import config from '../../config';
import NavBar from '../nav_sidebar/NavBar';
import SideBar from '../nav_sidebar/SideBar';
import useAxios from '../../utils/useAxios';


function ClinicPage() {
  const [userName, setUserName] = useState('');
 const [user, setUser] = useState([]);
 const axiosInstance = useAxios();
 const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
 const sidebarRef = useRef(null);
  const [clinics, setClinics] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const { authTokens } = useContext(AuthContext);


  const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle);
   };
  
   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axiosInstance.get('auth/users/me/');
          setUser(response.data);
          setUserName(response.data.full_name);
        } catch (error) {
          console.error('Failed to fetch data', error);
        }
      };
  
      fetchData();
  
      const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setOpenSidebarToggle(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
   }, [axiosInstance]);
  const doctors = ['Dr. Smith', 'Dr. Lee', 'Dr. Johnson'];
  const receptionists = ['Alice', 'Bob', 'Charlie'];
  const handleAddOrEdit = async (formData) => {
    try {
      setLoading(true); // Start loading
      let method = 'POST';
      let url = `${config.API_BASE_URL}/api/v1/clinic-management/clinic-profile/`;
  
      if (editIndex !== null) {
        method = 'PUT';
        const clinicId = clinics[editIndex].id;
        url += `${clinicId}/`; // Append clinic ID for editing
      }
  
      const user = JSON.parse(localStorage.getItem('user'));
      const isDoctorOwner = user?.roles.some(role => role.role_name === 'IS_DOCTOR_OWNER');
      const isadmin = user?.roles.some(role => role.role_name === 'IS_ADMIN');
  
      // Build the request body
      const requestBody = {
        clinic_name: formData['Clinic Name'],
        clinic_address: formData['Clinic Address'],
        contact: formData['Clinic Phone Number'],
        
      };
  
      // Conditionally add is_doctor_admin
      if (isDoctorOwner) {
        requestBody.is_doctor_admin = true;
      }
      if (isadmin) {
        requestBody.is_admin = true;
      }
  
      const clinicResponse = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${authTokens?.access}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!clinicResponse.ok) {
        throw new Error('Failed to save clinic');
      }
  
      const clinicData = await clinicResponse.json();
  
      // Update local state
      if (editIndex !== null) {
        const updatedList = [...clinics];
        updatedList[editIndex] = { ...formData, id: clinicData.id };
        setClinics(updatedList);
      } else {
        setClinics([...clinics, { ...formData, id: clinicData.id }]);
      }
  
      setShowPopup(false);
      setEditIndex(null);
      fetchClinics(); // Refresh the clinic list
  
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to save clinic information');
    } finally {
      setLoading(false); // Stop loading
    }
  };
  

  const fetchClinics = async () => {
    try {
      setLoading(true); // Start loading
      const response = await fetch(`${config.API_BASE_URL}/api/v1/clinic-management/client-clinic-profile/`, {
        headers: {
          Authorization: `JWT ${authTokens?.access}`,
        }
      });
      if (response.ok) {
        
        const data = await response.json();
        console.log(data)
        setClinics(data.map(clinic => ({
          'Clinic Name': clinic.clinic_name,
          'Clinic Address': clinic.clinic_address,
          'Clinic Phone Number': clinic.contact,
          id: clinic.id
        })));
      }
    } catch (error) {
      console.error('Error fetching clinics:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchClinics();
  }, []);

  const handleEdit = (id) => {
    const clinicIndex = clinics.findIndex(clinic => clinic.id === id);
    if (clinicIndex !== -1) {
      setEditIndex(clinicIndex);
      setShowPopup(true);
    }
  };

  const handleAddClinic = () => {
    setEditIndex(null);
    setShowPopup(true);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/api/v1/clinic-management/clinic-profile/${id}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `JWT ${authTokens?.access}`,
        }
      });

      if (response.ok) {
        setClinics(clinics.filter(clinic => clinic.id !== id));
      }
    } catch (error) {
      console.error('Error deleting clinic:', error);
      alert('Failed to delete clinic');
    }
  };

  const filteredClinics = clinics.filter((clinic) => {
    const clinicName = clinic['Clinic Name']?.toLowerCase() || '';
    return clinicName.includes(searchQuery.toLowerCase());
  });

  return (
    <div >
      <NavBar 
        OpenSidebar={OpenSidebar} 
        openSidebarToggle={openSidebarToggle} 
        userName={userName} 
      />
      <SideBar 
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle} 
        OpenSidebar={OpenSidebar} 
      />
    <div className="px-5 mt-4">
      <div className="d-flex justify-content-between">
        <p className="headings">List of Clinics</p>
        <button className="edit-btn" onClick={() => navigate("/admin")}>
          Back
        </button>
      </div>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="searchbar"
          placeholder="Search by Name"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button className="addbtn" onClick={handleAddClinic}>Add Clinic</button>
      </div>

      {showPopup && (
        <PopupForm
          fields={['Clinic Name', 'Clinic Address', 'Clinic Phone Number']}
          initialValues={editIndex !== null ? clinics[editIndex] : {}}
          onSubmit={handleAddOrEdit}
          onClose={() => setShowPopup(false)}
          doctors={doctors}
          receptionists={receptionists}
          showDoctorReceptionistCheckbox={true}
          popupname="Clinic"
        />
      )}

      {loading ? (
        <div className="text-center">Loading...</div> // Loading state
      ) : (
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered">
            <thead className="table-header">
              <tr>
                <th>Sl No.</th>
                <th>Clinic Name</th>
                <th>Address</th>
                <th>Phone Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            {filteredClinics.length > 0 ? (
              <tbody>
                {filteredClinics.map((clinic, index) => (
                  <tr key={clinic.id}>
                    <td>{index + 1}</td>
                    <td>{clinic['Clinic Name']}</td>
                    <td>{clinic['Clinic Address']}</td>
                    <td>{clinic['Clinic Phone Number']}</td>
                    <td>
                      <button className="btn edit-btn" onClick={() => handleEdit(clinic.id)}>
                        Edit
                      </button>
                      <button 
                        className="btn delete-btn"
                        onClick={() => handleDelete(clinic.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="5" className="text-center">
                    No Clinics available.
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      )}
    </div>
    </div>
  );
}

export default ClinicPage;