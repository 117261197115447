import React, { forwardRef, useEffect, useRef, useState ,useContext} from "react";
import { motion } from "framer-motion";
import { FiSend } from "react-icons/fi";
import GrowthChart from "./GrowthChart";
import useAxios from "../../utils/useAxios";
import axios from "axios";
import { Button, Modal, Form, ListGroup, InputGroup } from 'react-bootstrap';
import { SlArrowDown } from "react-icons/sl";
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import { format } from "date-fns";
import AuthContext from "../../context/AuthContext";

import config from "../../config";
const baseURL = `${config.API_BASE_URL}`;

const swal = require("sweetalert2");

const ExpandableCard = forwardRef(({ content, setExpanded }, ref) => {
  const data = {
    options: {
      grid: {
        show: false,
      },
      // colors: ["#256e6b","#bff8e5","#52AA7D"],
      chart: {
        type: "line",
        stacked: false,
      },
      xaxis: {
        categories: [
          "2001",
          "2002",
          "2003",
          "2004",
          "2005",
          "2006",
          "2007",
          "2008",
          "2009",
          "2010",
          "2011",
        ],
        axisBorder: {
          show: true,
          color: "#000",
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#000",
        },
        axisTicks: {
          show: false,
        },
      },
      stroke: {
        width: [3],
        curve: "smooth",
      },
      // plotOptions: {
      //   bar: {
      //     columnWidth: "50%"
      //   }
      // },

      // fill: {
      //   opacity: [0.85, 0.25, 1],
      //   gradient: {
      //     inverseColors: false,
      //     shade: "light",
      //     type: "vertical",
      //     opacityFrom: 0.85,
      //     opacityTo: 0.55,
      //     stops: [0, 100, 100, 100]
      //   }
      // },
      markers: {
        size: 0,
      },
      // tooltip: {
      //   shared: true,
      //   intersect: false,
      //   y: {
      //     formatter: function(y) {
      //       if (typeof y !== "undefined") {
      //         return y.toFixed(1);
      //       }
      //       return y;
      //     }
      //   }
      // }
    },
    series: [
      // {
      //   name: "People Age(in years)",
      //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
      //   type: "column",
      //   color: "#256e6b",
      //   borderWidth: 0,
      // },
      // {
      //   name: "People Height(in cm)",
      //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
      //   type: "area",
      //   // color: '#bff8e5',
      // },
      {
        name: "People Weight(in kg)",
        type: "line",
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
        color: "#52AA7D",
      },
    ],
  };

  return (
    <motion.div ref={ref} className="ExpandedCard" layoutId={content.id}>
      <motion.div className="card-title">
        <motion.h4>{content.heading}</motion.h4>
        <motion.div className="buttons">
          <motion.button className="btn">Add Data</motion.button>
          <motion.button className="btn">Edit</motion.button>
          <motion.button className="btn">Print</motion.button>
          <motion.button onClick={setExpanded} className="btn">
            Close
          </motion.button>
        </motion.div>
      </motion.div>
      {content.id === 1 ? (
        <>
          <motion.ul className="Chatbox">
            <motion.li style={{ paddingLeft: "0", fontSize: "1vw" }}>
              Please get your eyes dialated before appointment
            </motion.li>
          </motion.ul>
          <motion.form
            className="input-wrapper"
            style={{
              backgroundColor: "#ffffff",
              height: "15%",
              border: "1px solid #74C0C3",
              borderRadius: "10px",
            }}
          >
            <motion.input
              type="text"
              placeholder="Type something here..."
              id="message"
            />
            <motion.button
              type="submit"
              style={{ border: "none", background: "transparent" }}
            >
              <FiSend style={{ fontSize: "1.5vw", color: "#383c44" }} />
            </motion.button>
          </motion.form>
        </>
      ) : null}
      {content.id === 2 ? <GrowthChart state={data} /> : null}
      {content.id === 2 && <GrowthChart state={data} />}
      {content.id === 3 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>Comming Soon</h5>
        </motion.div>
      )}
      {content.id === 4 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>Comming Soon</h5>
        </motion.div>
      )}
      {content.id === 5 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>Comming Soon</h5>
        </motion.div>
      )}
    </motion.div>
  );
});

export const CompactCard = ({ content, setExpanded, patient }) => {
  const [PatientId, setPatientId] = useState();
  const axiosInstance = useAxios();

  const data = {
    options: {
      colors: ["#e9f6fa", "#256e6b", "#095D7E"],
      chart: {
        type: "line",
        stacked: false,
      },
      xaxis: {
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        axisBorder: {
          show: true,
          color: "#74C0C3",
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#000",
        },
        axisTicks: {
          show: false,
        },
      },
      stroke: {
        width: [0, 2, 5],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      grid: {
        show: false,
      },

      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(1);
            }
            return y;
          },
        },
      },
    },
    series: [
      {
        name: "Age",
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
        type: "area",
        color: "#256e6b",
        borderWidth: 0,
      },
      {
        name: "Height",
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
        type: "area",
        color: "#00e396",
      },
      {
        name: "Weight",
        type: "line",
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
        color: "#52AA7D",
      },
    ],
  };
  const [height, setHeight] = useState("");
  const { authTokens } = useContext(AuthContext);
  const [heightUnit, setHeightUnit] = useState("cm");
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [bp, setBP] = useState("");
  const [bmi, setBMI] = useState("");
  const [isHeightDropdownOpen, setIsHeightDropdownOpen] = useState(false);
  const [isWeightDropdownOpen, setIsWeightDropdownOpen] = useState(false);
  const [registrationFeeService, setRegistrationFeeService] = useState(null);
    const [firstVisitFeeService, setFirstVisitFeeService] = useState(null);
    const [reVisitFeeService, setReVisitFeeService] = useState(null);



  useEffect(() => {
    axiosInstance.get(`/api/v1/appointment/user-appointment/${patient}/`
    
    )
      .then((response) => setPatientId(response.data))
      .catch((error) => console.error("Error fetching data:", error));
    }, [patient]);
    
    const Patientid = PatientId?.patient_id;
    const type = PatientId?.type
    console.log("bbjk",PatientId)



    useEffect(() => {
      const fetchFeeStructure = async () => {
          try {
              const response = await fetch(`${config.API_BASE_URL}/api/v1/fee-structure/self/`, {
                  method: "GET",
                  headers: { Authorization: `JWT ${authTokens?.access}` },
              });
  
              const data = await response.json();
  
              // Extract specific services
              const registrationService = data.find(
                  (item) => item.fee_type_name === "Registration Fee" && item.cost_type === "DF"
              );
  
              // Example type - replace with actual type variable from props, state, or other source
               // Replace with actual dynamic value
  
              const firstVisitService =
                 data.find(
                            (item) => item.fee_type_name === "First Visit Fee" && item.cost_type === "DF"
                        )
                     
  
              const reVisitService =
                  data.find(
                            (item) => item.fee_type_name === "Re-Visit Fee" && item.cost_type === "DF"
                        )
                      
  
              setRegistrationFeeService(registrationService || null);
              setFirstVisitFeeService(firstVisitService || null);
              setReVisitFeeService(reVisitService || null);
          } catch (error) {
              console.error("Error fetching fee structure:", error);
          }
      };
  
      fetchFeeStructure();
  }, [authTokens]);
  
  const [billdata, setbilldata] = useState();
  useEffect(() => {
    console.log("auth",authTokens)
    fetch(
      `${config.API_BASE_URL}/api/v1/billing-app/billing/${doctorId}/${patient}/`,
      {
        method: "GET",
        headers: { Authorization: `JWT ${authTokens?.access}` },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setbilldata(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [authTokens]);
 console.log("bill",billdata)

//this is  will add
 const [vaccines, setVaccines] = useState([]);
  const [filteredVaccines, setFilteredVaccines] = useState([]);

  const fetchVaccines = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/api/v1/vaccine-app/assignments/patient/${patient}/`, {
        headers: { "Authorization": `JWT ${authTokens?.access}` }
      });
      console.log(response);
      setVaccines(response.data);
    } catch (error) {
      console.error('Error fetching vaccines:', error);
      
    }
  };

  useEffect(() => {
    fetchVaccines();
  }, [authTokens]); // Runs when doctorId or authTokens change

  // console.log("Today's Date:", today);
vaccines.forEach(vaccine => {
  vaccine.dosages.forEach(dosage => {
    console.log("Dosage Date:", dosage.scheduled_date);
  });
});
console.log("Vaccines Data:", vaccines);


  useEffect(() => {
    console.log("jhgvhm")
    // Get today's date in 'YYYY-MM-DD' format
    const today = new Date().toISOString().split('T')[0];
  
    // Filter vaccines with dosages scheduled for today
   
    const filtered = vaccines.filter(vaccine =>
      vaccine.dosages.some(dosage => dosage.scheduled_date === today)
      
    );


  
    // Map filtered vaccines to include only today's dosages
    const filteredData = filtered.map(vaccine => ({
      vaccine_name: vaccine.vaccine_name,
      vaccine_price: vaccine.vaccine_price,
      patient_id: vaccine.patient_id,
      dosages: vaccine.dosages.filter(dosage => dosage.scheduled_date === today),
    }));
   console.log("datavacc",filteredData)
    setFilteredVaccines(filteredData);
  }, [vaccines]);

  const closeHeightDropdown = () => setIsHeightDropdownOpen(false);
  const closeWeightDropdown = () => setIsWeightDropdownOpen(false);


  // Load initial data for the patient if it exists
  // useEffect(() => {
  //   console.log(patientData);
  //   const existingPatient = patientData.find((p) => p.id === patient);
  //   if (existingPatient) {
  //     console.log(existingPatient);
  //     setHeight(existingPatient.height);
  //     setWeight(existingPatient.weight);
  //     setBP(existingPatient.bp);
  //     setBMI(existingPatient.bmi);
  //   } else {
  //     setHeight("");
  //     setWeight("");
  //     setBP("");
  //     setBMI("");
  //   }
  // }, [patient, patientData]);

  useEffect(() => {
    const fetchPatientVitals = async () => {
      console.log("fetching vitals for ", Patientid);
      if (!Patientid || !axiosInstance) {
        console.warn("Patient ID or Auth Tokens are not available.");
        return;
      }
      try {
        const response = await axiosInstance.get(
          `api/v1/vitals/vitals/${Patientid}/`
        ); // Changed to GET request
        if (response.status === 200) {
          const data = response.data;
          console.log("height",data);
          setHeight(data.height || "");
          setWeight(data.weight || "");
          setBP(data.bp || "");
          setBMI(data.bmi || "");

         

        } else {
          console.error("Vitals not found");
          setHeight("");
          setWeight("");
          setBP("");
          setBMI("");
        }
      } catch (error) {
        console.error("Error fetching vitals", error);
        setHeight("");
          setWeight("");
          setBP("");
          setBMI("");
      }
    };

    fetchPatientVitals();
  }, [axiosInstance,Patientid]);

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };
  const toggleHeightDropdown = () =>
    setIsHeightDropdownOpen(!isHeightDropdownOpen);
  const toggleWeightDropdown = () =>
    setIsWeightDropdownOpen(!isWeightDropdownOpen);

  const handleHeightUnitSelect = (unit) => {
    if (unit !== heightUnit) {
      if (unit === "feet") {
        setHeight((prevHeight) => (prevHeight / 30.48).toFixed(2));
      } else {
        setHeight((prevHeight) => (prevHeight * 30.48).toFixed(2));
      }
      setHeightUnit(unit);
    }
    setIsHeightDropdownOpen(false);
  };

  const handleWeightUnitSelect = (unit) => {
    if (unit !== weightUnit) {
      if (unit === "gm") {
        setWeight((prevWeight) => (prevWeight * 1000).toFixed(2));
      } else {
        setWeight((prevWeight) => (prevWeight / 1000).toFixed(2));
      }
      setWeightUnit(unit);
    }
    setIsWeightDropdownOpen(false);
  };

  // const handleSubmit = () => {
  //   const newPatient = {
  //     id: patient,
  //     height: height,
  //     weight: weight,
  //     bp: bp,
  //     bmi: (weight / Math.pow(height / 100, 2)).toFixed(2),
  //   };

  //   const updatedPatientData = [
  //     ...patientData.filter((p) => p.id !== patient),
  //     newPatient,
  //   ];
  //   setPatientData(updatedPatientData);
  //   localStorage.setItem("patientData", JSON.stringify(updatedPatientData));
  //   console.log("updated vitals");
  //   console.log(localStorage.getItem("patientData"));
  //   swal.fire({
  //     title: "Vitals Updated",
  //     icon: "success",
  //     toast: true,
  //     timer: 6000,
  //     position: "top-right",
  //     timerProgressBar: true,
  //     showConfirmButton: false,
  //   });
  // };

  const handleSubmit = async () => {
    const newPatientVitals = {
      patient: Patientid, // patient ID
      height: heightUnit === "feet" ? (height * 30.48).toFixed(2) : height,
      weight: weightUnit === "gm" ? (weight / 1000).toFixed(2) : weight,
      bp: bp,
      bmi: ((weightUnit === "gm" ? (weight / 1000).toFixed(2) : weight) / Math.pow((heightUnit === "feet" ? (height * 30.48).toFixed(2) : height) / 100, 2)).toFixed(2),
    };
    setBMI(newPatientVitals.bmi);
    try {
      const response = await axiosInstance.post(
        "api/v1/vitals/vitals/",
        newPatientVitals
      );
      if (response.status === 201) {
        // Update the state with the new vitals
        setHeight(newPatientVitals.height);
        setWeight(newPatientVitals.weight);
        setBP(newPatientVitals.bp);
        setBMI(newPatientVitals.bmi);

      
        swal.fire({
          title: "Vitals Updated",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error submitting vitals", error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rows, setRows] = useState([{ col1: "", col2: "", col3: "" }]);
  const [patientReports, setPatientReports] = useState(() => {
    const data = localStorage.getItem("patientReports");
    return data ? JSON.parse(data) : {};
  });

  useEffect(() => {
    const existingReport = patientReports[patient] || {
      rows: [{ col1: "", col2: "", col3: "" }],
    };
    setRows(existingReport.rows);
  }, [patient, patientReports]);

  const handleOpenModal = () => {
    setRows(
      patientReports[patient]?.rows || [{ col1: "", col2: "", col3: "" }]
    );
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { col1: "", col2: "", col3: "" }]);
  };

  const handleSave = () => {
    const updatedReports = {
      ...patientReports,
      [patient]: { rows },
    };

    setPatientReports(updatedReports);
    localStorage.setItem("patientReports", JSON.stringify(updatedReports));
    swal.fire({
      title: "Report Saved",
      icon: "success",
      toast: true,
      timer: 6000,
      position: "top-right",
      timerProgressBar: true,
      showConfirmButton: false,
    });
    handleCloseModal();
  };







  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    const storedServices = localStorage.getItem('patientSelectedServices');
    if (storedServices) {
      const allPatientServices = JSON.parse(storedServices);
      setSelectedServices(allPatientServices[patient] || []);
    }
  }, [patient]);


  const handleServiceChange = (service) => {
    setSelectedServices(prevSelected => {
      const isSelected = prevSelected.some(s => s.id === service.id);
      const newSelected = isSelected
        ? prevSelected.filter(s => s.id !== service.id)
        : [...prevSelected, service];
  
      // Update localStorage with patient-specific services
      const allPatientServices = JSON.parse(localStorage.getItem('patientSelectedServices') || '{}');
      allPatientServices[patient] = newSelected;
      localStorage.setItem('patientSelectedServices', JSON.stringify(allPatientServices));
  
      return newSelected;
    });
  };
  
  
  console.log({selectedServices})
  console.log({patient})




  const [showModal, setShowModal] = useState(false);
  const [vaccineList, setVaccineList] = useState([]);
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [completedDoses, setCompletedDoses] = useState({});
  const [scheduledDoses, setScheduledDoses] = useState({});
  const [patientVaccines, setPatientVaccines] = useState([]);
  const [showAddVaccineContainer, setShowAddVaccineContainer] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(null);
 
  const [editingVaccine, setEditingVaccine] = useState(null);

  const doctorData = JSON.parse(localStorage.getItem('user'));
  const doctorId = doctorData?.id;

 
// Fetch all vaccines from backend
useEffect(() => {
axiosInstance.get(`/api/v1/vaccine-app/vaccines/${doctorId}/`

)
  .then((response) => setVaccineList(response.data))
  .catch((error) => console.error("Error fetching vaccine data:", error));
}, []);
  // Fetch all vaccines from backend
  useEffect(() => {
    axiosInstance.get(`/api/v1/vaccine-app/vaccines/${doctorId}/`)
      .then((response) => setVaccineList(response.data))
      .catch((error) => console.error("Error fetching vaccine data:", error));
  }, [doctorId, axiosInstance]);

  // Fetch saved vaccines for the patient
  const fetchPatientVaccines = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/vaccine-app/assignments/patient/${patient}/`);
      setPatientVaccines(response.data); // Update state only if successful
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle empty patient vaccines
        console.warn("No vaccines found for this patient.");
        setPatientVaccines([]); // Clear state if no data
      } else {
        console.error("Error fetching patient vaccine data:", error);
      }
    }
  };
  useEffect(() => {
    if (patient) {
      fetchPatientVaccines();
    }
  }, [patient,axiosInstance]);

  console.log("gfh",patientVaccines)
  const toggleModal = () => {
    setShowModal(!showModal);
    
  };

  const toggleAddVaccineContainer = () => {
    setShowAddVaccineContainer(!showAddVaccineContainer);
  };

  const handleSelectVaccine = (vaccineId) => {
    const vaccine = vaccineList.find((v) => v.vaccine_id === parseInt(vaccineId, 10));
    setSelectedVaccine(vaccine);
    setCompletedDoses({});
    setScheduledDoses({});
  };

  const hasIntervalDoses = () => {
    return selectedVaccine?.dosages?.[0]?.vaccine_interval && 
           selectedVaccine?.dosages?.[0]?.interval_type;
  };
  const calculateFutureDoseDate = (startDate, interval, intervalType) => {
    const date = new Date(startDate);
    
    // Handle interval type (months or days)
    if (intervalType === 'months') {
      date.setMonth(date.getMonth() + interval); // Add months
    } else if (intervalType === 'days') {
      date.setDate(date.getDate() + interval); // Add days
    } else {
      console.error('Unknown interval type:', intervalType);
      return null;
    }
  
    return format(date, 'yyyy-MM-dd'); // Return formatted date as 'YYYY-MM-DD'
  };
  
  
  
  const handleDoseCompletion = (doseIndex) => {
    const today = new Date();
    const todayFormatted = format(today, 'yyyy-MM-dd');
  
    setCompletedDoses(prev => {
      const newCompletedDoses = { ...prev };
      
      if (prev[doseIndex]) {
        delete newCompletedDoses[doseIndex];
        return newCompletedDoses;
      }
  
      newCompletedDoses[doseIndex] = todayFormatted;
      return newCompletedDoses;
    });
  
    if (hasIntervalDoses() && !completedDoses[doseIndex]) {
      const interval = selectedVaccine.dosages[0].vaccine_interval;
      const intervalType = selectedVaccine.dosages[0].interval_type;
      const totalDoses = selectedVaccine.dosages[0].vaccine_dosages;
  
      const plannedDates = {};
      let lastScheduledDate = today; // Start with the current date for the first dose
  
      // If first dose is completed, calculate subsequent doses based on that
      if (doseIndex === 1 && completedDoses[1]) {
        lastScheduledDate = new Date(completedDoses[1]);
      }
  
      console.log('Starting with lastScheduledDate:', lastScheduledDate);
      console.log('Interval:', interval, 'Interval Type:', intervalType);
  
      for (let i = doseIndex + 1; i <= totalDoses; i++) {
        const nextDate = calculateFutureDoseDate(
          lastScheduledDate,
          interval,
          intervalType
        );
        
        console.log(`Next scheduled date for dose ${i}:`, nextDate);
  
        plannedDates[i] = nextDate;
        lastScheduledDate = new Date(nextDate); // Update lastScheduledDate for next iteration
      }
  
      setScheduledDoses(prev => ({
        ...prev,
        ...plannedDates
      }));
    }
  };
  
  
  
  

  const handleScheduleDate = (doseIndex) => {
    setOpenDatePicker(doseIndex);
  };
  console.log('Completed Doses:', completedDoses);
console.log('Scheduled Doses:', scheduledDoses);
console.log('Selected Vaccine:', selectedVaccine);


  const handleDateChange = (date, doseNumber) => {
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      setScheduledDoses((prev) => ({
        ...prev,
        [doseNumber]: formattedDate,
      }));
      setOpenDatePicker(null);
    }
  };
console.log("bmjbbn",patient)

// Function to handle editing a vaccine assignment
const handleEditVaccine = (vaccineAssignment) => {
  console.log("Editing vaccine:", vaccineAssignment);
  
  // Find the corresponding vaccine from vaccineList
  const editingVaccineDetails = vaccineList.find(
    vaccine => vaccine.vaccine_id === vaccineAssignment.vaccine
  );

  // Update state with both the assignment and the vaccine details
  setEditingVaccine({
    ...vaccineAssignment,
    vaccine_details: editingVaccineDetails,
    dosages: vaccineAssignment.dosages.map(dose => ({
      ...dose,
      original_scheduled_date: dose.scheduled_date,
      original_completed_date: dose.completed_date,
    }))
  });

  // Set the selected vaccine to the one being edited
  setSelectedVaccine(editingVaccineDetails);
  
  // Open the add vaccine container
  setShowAddVaccineContainer(true);

  // Reset dose tracking
  const initialCompletedDoses = {};
  const initialScheduledDoses = {};

  // Populate completed and scheduled doses from the existing assignment
  vaccineAssignment.dosages.forEach(dose => {
    if (dose.completed_date) {
      initialCompletedDoses[dose.dosage_number] = dose.completed_date;
    }
    if (dose.scheduled_date) {
      initialScheduledDoses[dose.dosage_number] = dose.scheduled_date;
    }
  });

  setCompletedDoses(initialCompletedDoses);
  setScheduledDoses(initialScheduledDoses);
};
useEffect(() => {
  console.log("Add vaccine container state:", showAddVaccineContainer);
}, [showAddVaccineContainer]);


// Function to handle deleting a vaccine assignment
const handleDeleteVaccine = async (vaccineAssignmentId) => {
  try {
    const confirmDelete = window.confirm("Are you sure you want to delete this vaccine assignment?");
    if (confirmDelete) {
      const response = await axiosInstance.delete(`/api/v1/vaccine-app/assignments/patient/${vaccineAssignmentId}/`);
      
      if (response.status === 204 || response.status === 200) {
        // await fetchPatientVaccines();
        alert('Vaccine assignment deleted successfully!');
        await fetchPatientVaccines();  // Refresh data after deletion
      } else {
        throw new Error('Failed to delete vaccine');
      }
    }
  } catch (error) {
    console.error('Error deleting vaccine assignment:', error);
    alert('Error deleting vaccine assignment. Please try again.');
  }
};


console.log("hgjhkljkl;lkl;k;lbhb",editingVaccine)

  // Modify handleSaveSubmit to handle both new and edit scenarios
  const handleSaveSubmit = async () => {
    try {
      const dosages = Array.from(
        { length: selectedVaccine.dosages[0].vaccine_dosages },
        (_, i) => ({
          dosage_number: i + 1,
          scheduled_date: scheduledDoses[i + 1] || completedDoses[i + 1],
          completed_date: completedDoses[i + 1] || null,
        })
      ).filter(dose => dose.scheduled_date !== null || dose.completed_date !== null);

      const dataToSend = {
        doctor_id: doctorId,
        patient_id: patient,
        vaccine: selectedVaccine.vaccine_id,
        dosages,
      };
         
      console.log("dgfhjgjkhkjjlklkl",dataToSend)
      // Check if we're editing an existing vaccine assignment
      if (editingVaccine) {
        // Send PUT request to update existing assignment
        await axiosInstance.patch(`/api/v1/vaccine-app/assignments/patient/${editingVaccine.patient_id}/`, dataToSend);
      } else {
        // Send POST request for new assignment
        await axiosInstance.post('/api/v1/vaccine-app/assignments/', dataToSend);
      }
      
      const response = await axiosInstance.get(`/api/v1/vaccine-app/assignments/patient/${patient}/`);
      setPatientVaccines(response.data);
      
      // Reset states
      setShowAddVaccineContainer(false);
      setSelectedVaccine(null);
      setEditingVaccine(null);
      setCompletedDoses({});
      setScheduledDoses({});
      
      alert('Vaccine data saved successfully!');
    } catch (error) {
      console.error('Error saving vaccine data:', error);
      alert('Error saving vaccine data. Please try again.');
    }
  };


  const renderDoseScheduling = (doseNumber) => {
    if (hasIntervalDoses()) {
      // For interval doses, only show the scheduled date
      return scheduledDoses[doseNumber] ? (
        <span className="ml-4 text-blue-500">
          Next planned: {scheduledDoses[doseNumber]}
        </span>
      ) : null;
    } else {
      // For non-interval doses, show the schedule button and date picker
      return (
        <>
          <Button 
            variant="link"
            className="text-blue-500 hover:text-blue-600"
            onClick={() => handleScheduleDate(doseNumber)}
          >
            Schedule
          </Button>
          {openDatePicker === doseNumber && (
            <div className="mt-2">
              <DatePicker
                selected={scheduledDoses[doseNumber] ? new Date(scheduledDoses[doseNumber]) : null}
                onChange={(date) => handleDateChange(date, doseNumber)}
                dateFormat="yyyy-MM-dd"
                inline
              />
            </div>
          )}
        </>
      );
    }
  };
  return (
    <motion.div
      className="CompactCard"
      layoutId={content.id}
      // onClick={setExpanded}
    >
      <motion.div className="heading">{content.heading}</motion.div>
      {content.id === 1 && (
        <motion.ul className="Chats">
          <motion.li style={{ paddingLeft: "0", marginTop: "15px" }}>
            Please get your eyes dialated before appointment
          </motion.li>
        </motion.ul>
      )}
      {content.id === 2 && <GrowthChart state={data} />}
      {content.id === 3 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "fit-content",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                margin: "10px 0",
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #0A9396",
                backgroundColor: "#fff",
              }}
            >
              <label
                style={{
                  padding: "8px 10px",
                  background: "#0A9396",
                  borderRadius: "9px",
                  color: "white",
                }}
              >
                Height
              </label>
              <input
                type="number"
                placeholder="Height (in cm)"
                value={height}
                onChange={handleHeightChange}
                style={{ padding: "10px", flex: 1 }}
              />
              <div
                style={{ position: "relative", width: "20%" }}
                onMouseLeave={closeHeightDropdown}
              >
                <div
                  onClick={toggleHeightDropdown}
                 className="vitalselectedunit"
                >
                  {heightUnit}
                </div>
                {isHeightDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      // top: '100%',
                      left: 0,
                      right: 0,
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      borderTop: "none",
                      borderRadius: "9px",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      onClick={() => handleHeightUnitSelect("cm")}
                      style={{
                        borderRadius: "9px 9px 0 0",
                      }}
                      className="vitaldropdown"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E0F2F1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      cm
                    </div>
                    <div
                      onClick={() => handleHeightUnitSelect("feet")}
                      style={{
                        borderRadius: "0 0 9px 9px",
                      }}
                      className="vitaldropdown"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E0F2F1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      feet
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                margin: "10px 0",
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #0A9396",
                backgroundColor: "#fff",
              }}
            >
              <label
                style={{
                  padding: "8px 9px",
                  background: "#0A9396",
                  borderRadius: "9px",
                  color: "white",
                }}
              >
                Weight
              </label>
              <input
                type="number"
                placeholder="Weight (in kg)"
                value={weight}
                onChange={handleWeightChange}
                style={{ padding: "10px", flex: 1 }}
              />
              <div
                style={{ position: "relative", width: "20%" }}
                onMouseLeave={closeWeightDropdown}
              >
                <div
                  onClick={toggleWeightDropdown}
                   className="vitalselectedunit"
                >
                  {weightUnit}
                </div>
                {isWeightDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "9px",
                      borderTop: "none",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      onClick={() => handleWeightUnitSelect("kg")}
                      style={{
                        borderRadius: "9px 9px 0 0",
                      }}
                      className="vitaldropdown"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E0F2F1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      kg
                    </div>
                    <div
                      onClick={() => handleWeightUnitSelect("gm")}
                      style={{
                        borderRadius: "0 0 9px 9px",
                      }}
                      className="vitaldropdown"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E0F2F1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      gm
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                margin: "10px 0",
                padding: "0px",
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #0A9396",
                backgroundColor: "#fff",
              }}
            >
              <label
                style={{
                  padding: "8px 25px",
                  background: "#0A9396",
                  borderRadius: "9px",
                  color: "white",
                }}
              >
                BP
              </label>
              <input
                type="text"
                placeholder="Blood Pressure (e.g., 120/80)"
                value={bp}
                onChange={(e) => setBP(e.target.value)}
                style={{
                  padding: "10px",
                  flex: 1,
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                margin: "10px 0",
                padding: "0px",
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #0A9396",
                backgroundColor: "#fff",
              }}
            >
              <label
                style={{
                  padding: "8px 20px",
                  background: "#0A9396",
                  borderRadius: "9px",
                  color: "white",
                }}
              >
                BMI
              </label>
              <input
                type="text"
                placeholder="BMI"
                value={bmi}
                readOnly
                style={{
                  padding: "10px",
                  width: "100%",
                }}
              />
            </div>
            <button
              onClick={handleSubmit}
              style={{
                padding: "10px 20px",
                marginTop: "10px",
                borderRadius: "10px",
                border: "none",
                background: "#0A9396",
                color: "#fff",
                cursor: "pointer",
                width: "100%",
              }}
            >
              Submit
            </button>
          </div>
        </motion.div>
      )}
      {content.id === 4 && (
        <div>
          <motion.div
            style={{
              color: "#005F73",
              width: "100%",
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "500",
            }}
            onClick={handleOpenModal}
          >
            Report
          </motion.div>
          {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <div className="d-flex justify-content-between m-1">
                  <h2>Report Form</h2>
                  <div
                    type="button"
                    onClick={handleCloseModal}
                    style={{ color: "red", fontSize: "20px" }}
                  >
                    Close
                  </div>
                </div>
                <div className="heading-report">
                  <th>Investigation Name</th>
                  <th>Value</th>
                  <th>Units</th>
                </div>
                <form>
                  {rows.map((row, index) => (
                    <div key={index} className="input-rows">
                      <input
                        type="text"
                        name="col1"
                        value={row.col1}
                        onChange={(e) => handleInputChange(index, e)}
                        className="input"
                      />
                      <input
                        type="number"
                        name="col2"
                        placeholder=""
                        value={row.col2}
                        onChange={(e) => handleInputChange(index, e)}
                        className="input"
                      />
                      <input
                        type="text"
                        className="input"
                        name="col3"
                        placeholder=""
                        value={row.col3}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                  ))}
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        type="button"
                        onClick={handleAddRow}
                        className="btns-report"
                      >
                        Add Row
                      </button>
                      <button
                        type="button"
                        onClick={handleSave}
                        className="btns-report"
                      >
                        Save
                      </button>
                    </div>
                    <div style={{ fontSize: "19px" }}>View All Reports</div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
      {content.id === 5 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "80%",
            // display: "flex",
             
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          
          <div className="bill-table" style={{height:"200px" ,width:"100%",overflow:"auto"}}>
          <table  >
    <thead >
      <tr>
        <th>Service Name</th>
        <th>Fee Amount</th>
      </tr>
    </thead>
    <tbody  >

     
      {selectedServices && selectedServices.length > 0 ? (
        <>

{registrationFeeService && (
  <tr>
    <td>{registrationFeeService?.fee_type_name || "Registration Fee"}</td>
    <td>{registrationFeeService?.fee_amount || "N/A"}</td>
  </tr>
)}
{type === "IA" && (
  <tr>
    <td>{firstVisitFeeService?.fee_type_name || "First Visit Fee"}</td>
    <td>{firstVisitFeeService?.fee_amount || "N/A"}</td>
  </tr>
)}
{type === "FA" &&  (
  <tr>
    <td>{reVisitFeeService?.fee_type_name || "Re-Visit Fee"}</td>
    <td>{reVisitFeeService?.fee_amount || "N/A"}</td>
  </tr>
)}


      {billdata && billdata.services ? (
        <>

          {billdata.services.map((service) => (
                        <tr key={service.id}>
                            <td>{service.services_name}</td>
                            <td>{service.cost_of_service}</td>
                        </tr>
                    ))}
                    </>
                  ) : (
                    <></>
                  )}

{filteredVaccines.length === 0 ? (
          <>
          
          </>
        ) : (
          filteredVaccines.map((vaccine, vaccineIndex) =>
            vaccine.dosages.map((dosage, dosageIndex) => (
              <tr key={`${vaccineIndex}-${dosageIndex}`}>
                {/* <td>{vaccine.vaccine_name}</td> */}
                <td>{vaccine.vaccine_name}(Dose-{dosage.dosage_number})</td>
                {/* <td>{dosage.scheduled_date}</td> */}
                <td>{vaccine.vaccine_price}</td>
                {/* <td>{vaccine.patient_id}</td> */}
              </tr>
            ))
          )
        )}
        </>
      ) : (
        <tr>
          <td colSpan="2" className="no-services">No services selected</td>
        </tr>
      )}
     
    </tbody>
  </table>
          </div>


        </motion.div>
      )}
      {content.id === 6 && (
        <div>
        <motion.div
        style={{
          color: "#005F73",
          width: "100%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize:"20px",
          fontWeight:"500"
        }}
        onClick={toggleModal}
        
      >
        Vaccines
      </motion.div>
      

      {/* Modal for adding and tracking new vaccine doses */}
      <Modal show={showModal} onHide={toggleModal} className="max-w-4xl" >
      <Modal.Header closeButton>
        <Modal.Title>Vaccines for Patient ID: {patient}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup className="mb-3">
          {patientVaccines.map((vaccine, index) => (
            <ListGroup.Item key={index}>
              <strong>{vaccine.vaccine_name}</strong>
              <ul className="list-none pl-4">
                {vaccine.dosages.map((dose, i) => (
                  <li key={i}>
                    Dose {dose.dosage_number} - 
                    {dose.completed_date ? (
                      <span className="text-green-500 ml-2">
                        Completed on: {dose.completed_date}
                      </span>
                    ) : dose.scheduled_date ? (
                      <span className="text-yellow-500 ml-2">
                        Scheduled for: {dose.scheduled_date}
                      </span>
                    ) : (
                      <span className="text-red-500 ml-2">Not scheduled</span>
                    )}
                  </li>
                ))}
              </ul>
              <div className="d-flex space-x-2">
                      <Button 
                        variant="warning"
                        className="bg-yellow-500 hover:bg-yellow-600 text-white"
                        onClick={() => handleEditVaccine(vaccine)}
                      >
                        Edit
                      </Button>
                      <Button 
                        variant="danger"
                        className="bg-red-500 hover:bg-red-600 text-white"
                        onClick={() => handleDeleteVaccine(vaccine.patient_id)}
                      >
                        Delete
                      </Button>
                    </div>
            </ListGroup.Item>
          ))}
        </ListGroup>

        <Button 
          variant="secondary"
          className="bg-gray-500 hover:bg-gray-600"
          onClick={toggleAddVaccineContainer}
        >
          Add Vaccine
        </Button>

        {showAddVaccineContainer && (
          <div className="mt-3">
            <Form.Group controlId="vaccineSelect">
              <Form.Label>Select Vaccine</Form.Label>
              <Form.Select
                value={editingVaccine ? editingVaccine.vaccine : (selectedVaccine ? selectedVaccine.vaccine_id : '')}
                onChange={(e) => handleSelectVaccine(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">-- Select Vaccine --</option>
                {vaccineList.map((vaccine) => (
                  <option key={vaccine.vaccine_id} value={vaccine.vaccine_id}>
                    {vaccine.vaccine_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {selectedVaccine && (
              <ListGroup className="mt-3">
                <h5>
                  Doses for {selectedVaccine.vaccine_name}
                  {hasIntervalDoses() && (
                    <span className="text-sm text-gray-500 ml-2">
                      (Interval: {selectedVaccine.dosages[0].vaccine_interval} {selectedVaccine.dosages[0].interval_type})
                    </span>
                  )}
                </h5>
                {Array.from({ length: selectedVaccine.dosages[0].vaccine_dosages }).map((_, i) => {
                  const doseNumber = i + 1;
                  return (
                    <ListGroup.Item key={doseNumber} className="p-3">
                      <div className="flex items-center">
                        <InputGroup.Checkbox
                          checked={!!completedDoses[doseNumber]}
                          onChange={() => handleDoseCompletion(doseNumber)}
                        />
                        <span className="ml-2">
                          Dose {doseNumber}
                        </span>
                        {completedDoses[doseNumber] ? (
                          <span className="ml-4 text-green-500">
                            Completed: {completedDoses[doseNumber]}
                          </span>
                        ) : renderDoseScheduling(doseNumber)}
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            )}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        {/* <Button 
          variant="secondary"
          className="bg-gray-500 hover:bg-gray-600"
          onClick={toggleModal}
        >
          Close
        </Button> */}
        {showAddVaccineContainer && selectedVaccine && (
          <Button 
            variant="success"
            className="bg-green-500 hover:bg-green-600"
            onClick={handleSaveSubmit}
          >
           {editingVaccine ? 'Update' : 'Save & Submit'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>

     </div>
      
      )}
    </motion.div>
  );
};

const Card = ({ content, patient }) => {
  const [isExpanded, setExpanded] = useState(false);
  const cardRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return isExpanded ? (
    <ExpandableCard
      ref={cardRef}
      setExpanded={() => setExpanded(false)}
      content={content}
    />
  ) : (
    <CompactCard
      setExpanded={() => setExpanded(true)}
      content={content}
      patient={patient}
    />
  );
};

export default Card;
